import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class ListEntItem extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // title

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elTitle = async () => {
    // Go to screen 'Ent Details'
    this.context.appActions.goToScreen('entDetails', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elHotspot = async () => {
    // Go to screen 'Ent Details'
    this.context.appActions.goToScreen('entDetails', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    
    const style_elClickableBg = {
      background: 'rgba(255, 255, 255, 0.000)',
     };
    
    const value_title = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.title);
    
    const style_elTitle = {
      fontSize: 20.0,
      color: '#1436ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ListEntItem">
        <div className="background">
          <div className="elClickableBg" style={style_elClickableBg} />
        </div>
        
        <div className="layoutFlow">
          <div className="elTitle">
            <div className="font-helveticaBold" style={style_elTitle} onClick={this.onClick_elTitle} >
              <div>{value_title}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="actionFont elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
