import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elYoutubecircle from './images/Social_elYoutubecircle_797930.png';
import img_elTwitter_circle from './images/Social_elTwitter_circle_565811.png';
import img_elIg_circle from './images/Social_elIg_circle_65880.png';
import img_elFb_circle from './images/Social_elFb_circle_917589.png';

export default class Social extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elYoutubecircle = async () => {
    window.open('https://www.youtube.com/watch?v=l0DLiLXu7NQ&feature=youtu.be', '_blank');
  
  }
  
  
  onClick_elTwitter_circle = async () => {
    window.open('https://twitter.com/it365solutions', '_blank');
  
  }
  
  
  onClick_elIg_circle = async () => {
    window.open('https://www.instagram.com/it365solutions/?hl=en', '_blank');
  
  }
  
  
  onClick_elFb_circle = async () => {
    window.open('https://www.facebook.com/it365solutions', '_blank');
  
  }
  
  
  render() {
    const style_elYoutubecircle = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTwitter_circle = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIg_circle = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFb_circle = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Social">
        <div className="foreground">
          <img className="elYoutubecircle" style={style_elYoutubecircle} src={img_elYoutubecircle} alt="" onClick={this.onClick_elYoutubecircle}  />
          <img className="elTwitter_circle" style={style_elTwitter_circle} src={img_elTwitter_circle} alt="" onClick={this.onClick_elTwitter_circle}  />
          <img className="elIg_circle" style={style_elIg_circle} src={img_elIg_circle} alt="" onClick={this.onClick_elIg_circle}  />
          <img className="elFb_circle" style={style_elFb_circle} src={img_elFb_circle} alt="" onClick={this.onClick_elFb_circle}  />
        </div>
      </div>
    )
  }
  
}
