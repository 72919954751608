import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CategoriesTabs from './CategoriesTabs';
import ImageTesting from './ImageTesting';
import Footer from './Footer';
import HeaderComponent from './HeaderComponent';

export default class QAServicesScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elEmailLink = async () => {
    window.open('mailto:support@it365solutionsinc.com', '_blank');
  
  }
  
  
  onClick_elCareersEmailLink = async () => {
    window.open('mailto:support@it365solutionsinc.com', '_blank');
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#c7ebf6',
     };
    const style_elQAServices = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elQaStatement = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTeam = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTeamText = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elReporting = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elReportingText = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elContactUs = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elContactDetails = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elEmailLink = {
      fontSize: 25.0,
      color: '#0432ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elJoinTeamText = {
      fontSize: 33.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elSendResumeText = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elCareersEmailLink = {
      fontSize: 25.0,
      color: '#0432ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen QAServicesScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elCategoriesTabs">
            <div>
              <CategoriesTabs ref={(el)=> this._elCategoriesTabs = el} />
            </div>
          </div>
          
          <div className="elQAServices">
            <div className="font-silom" style={style_elQAServices}>
              <div>{this.context.locStrings.aboutus2_mission_161445}</div>
            </div>
          </div>
          
          <div className="elQaStatement">
            <div className="font-symbol" style={style_elQaStatement}>
              <div>{this.context.locStrings.aboutus2_missionstatement_589207}</div>
            </div>
          </div>
          
          <div className="elSpacer3">
            <div />
          </div>
          
          <div className="hasNestedComps elImageTesting">
            <div>
              <ImageTesting ref={(el)=> this._elImageTesting = el} />
            </div>
          </div>
          
          <div className="elTeam">
            <div className="font-silom" style={style_elTeam}>
              <div>{this.context.locStrings.aboutus2_mathapps_550395}</div>
            </div>
          </div>
          
          <div className="elTeamText">
            <div className="font-symbol" style={style_elTeamText}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.qaservices_comingsoontextcopy_739340.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elReporting">
            <div className="font-silom" style={style_elReporting}>
              <div>{this.context.locStrings.aboutus2_privacypolicy_1040213}</div>
            </div>
          </div>
          
          <div className="elReportingText">
            <div className="font-symbol" style={style_elReportingText}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.aboutus2_privacypolicylink_222926.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elContactUs">
            <div className="font-silom" style={style_elContactUs}>
              <div>{this.context.locStrings.aboutus2_contactus_620638}</div>
            </div>
          </div>
          
          <div className="elContactDetails">
            <div className="font-symbol" style={style_elContactDetails}>
              <div>{this.context.locStrings.aboutus2_contactdetails_357559}</div>
            </div>
          </div>
          
          <div className="elEmailLink">
            <div className="font-symbol" style={style_elEmailLink} onClick={this.onClick_elEmailLink} >
              <div>{this.context.locStrings.aboutus2_emaillink_760067}</div>
            </div>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elJoinTeamText">
            <div className="systemFontBoldItalic" style={style_elJoinTeamText}>
              <div>{this.context.locStrings.qaservices_contactdetailscopy_121307}</div>
            </div>
          </div>
          
          <div className="elSendResumeText">
            <div className="font-symbol" style={style_elSendResumeText}>
              <div>{this.context.locStrings.qaservices_jointeamtextcopy_932484}</div>
            </div>
          </div>
          
          <div className="elCareersEmailLink">
            <div className="font-symbol" style={style_elCareersEmailLink} onClick={this.onClick_elCareersEmailLink} >
              <div>{this.context.locStrings.qaservices_emaillinkcopy_437173}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} />
            </div>
          </div>
          
          <div className="elSpacerCopy2">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elHeaderComponent">
              <HeaderComponent ref={(el)=> this._elHeaderComponent = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
