import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CategoriesTabs from './CategoriesTabs';
import Footer from './Footer';
import HeaderComponent from './HeaderComponent';

export default class PrivacyPolicyScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elGooglePlaySvcLink = async () => {
    window.open('https://www.google.com/policies/privacy/', '_blank');
  
  }
  
  
  onClick_elAdMobLink = async () => {
    window.open('https://support.google.com/admob/answer/6128543?hl=en', '_blank');
  
  }
  
  
  onClick_elGoogleAnalyticsLink = async () => {
    window.open('https://firebase.google.com/policies/analytics', '_blank');
  
  }
  
  
  onClick_elEmailLink = async () => {
    window.open('mailto:support@it365solutionsinc.com', '_blank');
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#bceef6',
     };
    const style_elPrivacyPolicy = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elText2 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy2 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elInformation = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy3 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy4 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy5 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elGooglePlaySvcLink = {
      fontSize: 25.0,
      color: '#0432ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elAdMobLink = {
      fontSize: 25.0,
      color: '#0432ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elGoogleAnalyticsLink = {
      fontSize: 25.0,
      color: '#0432ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCookies = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy6 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy7 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elLinks = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy8 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elChildrensPrivacy = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy9 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elChanges = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy10 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy11 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elContactUs = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy12 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elEmailLink = {
      fontSize: 25.0,
      color: '#0432ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen PrivacyPolicyScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elCategoriesTabs">
            <div>
              <CategoriesTabs ref={(el)=> this._elCategoriesTabs = el} />
            </div>
          </div>
          
          <div className="elPrivacyPolicy">
            <div className="font-DINAlternateBold" style={style_elPrivacyPolicy}>
              <div>{this.context.locStrings.privacypolicy_text_312621}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="font-AppleSystemUIFont" style={style_elText2}>
              <div>{this.context.locStrings.privacypolicy_text2_391011}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy}>
              <div>{this.context.locStrings.privacypolicy_textcopy_449292}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy2}>
              <div>{this.context.locStrings.privacypolicy_textcopy2_218886}</div>
            </div>
          </div>
          
          <div className="elInformation">
            <div className="font-DINAlternateBold" style={style_elInformation}>
              <div>{this.context.locStrings.privacypolicy_privacypolicycopy_108848}</div>
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy3}>
              <div>{this.context.locStrings.privacypolicy_textcopy3_698180}</div>
            </div>
          </div>
          
          <div className="elTextCopy4">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy4}>
              <div>{this.context.locStrings.privacypolicy_textcopy4_710396}</div>
            </div>
          </div>
          
          <div className="elTextCopy5">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy5}>
              <div>{this.context.locStrings.privacypolicy_textcopy5_126935}</div>
            </div>
          </div>
          
          <div className="elGooglePlaySvcLink">
            <div className="font-AppleSystemUIFont" style={style_elGooglePlaySvcLink} onClick={this.onClick_elGooglePlaySvcLink} >
              <div>{this.context.locStrings.privacypolicy_textcopy6_414150}</div>
            </div>
          </div>
          
          <div className="elAdMobLink">
            <div className="font-AppleSystemUIFont" style={style_elAdMobLink} onClick={this.onClick_elAdMobLink} >
              <div>{this.context.locStrings.privacypolicy_googleplaysvclinkcopy_988883}</div>
            </div>
          </div>
          
          <div className="elGoogleAnalyticsLink">
            <div className="font-AppleSystemUIFont" style={style_elGoogleAnalyticsLink} onClick={this.onClick_elGoogleAnalyticsLink} >
              <div>{this.context.locStrings.privacypolicy_admoblinkcopy_954912}</div>
            </div>
          </div>
          
          <div className="elCookies">
            <div className="font-DINAlternateBold" style={style_elCookies}>
              <div>{this.context.locStrings.privacypolicy_informationcopy_443560}</div>
            </div>
          </div>
          
          <div className="elTextCopy6">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy6}>
              <div>{this.context.locStrings.privacypolicy_textcopy6_242825}</div>
            </div>
          </div>
          
          <div className="elTextCopy7">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy7}>
              <div>{this.context.locStrings.privacypolicy_textcopy7_618762}</div>
            </div>
          </div>
          
          <div className="elLinks">
            <div className="font-DINAlternateBold" style={style_elLinks}>
              <div>{this.context.locStrings.privacypolicy_cookiescopy_876198}</div>
            </div>
          </div>
          
          <div className="elTextCopy8">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy8}>
              <div>{this.context.locStrings.privacypolicy_textcopy8_578902}</div>
            </div>
          </div>
          
          <div className="elChildrensPrivacy">
            <div className="font-DINAlternateBold" style={style_elChildrensPrivacy}>
              <div>{this.context.locStrings.privacypolicy_linkscopy_390084}</div>
            </div>
          </div>
          
          <div className="elTextCopy9">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy9}>
              <div>{this.context.locStrings.privacypolicy_textcopy9_466598}</div>
            </div>
          </div>
          
          <div className="elChanges">
            <div className="font-DINAlternateBold" style={style_elChanges}>
              <div>{this.context.locStrings.privacypolicy_childrensprivacycopy_762520}</div>
            </div>
          </div>
          
          <div className="elTextCopy10">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy10}>
              <div>{this.context.locStrings.privacypolicy_textcopy10_650743}</div>
            </div>
          </div>
          
          <div className="elTextCopy11">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy11}>
              <div>{this.context.locStrings.privacypolicy_textcopy11_409749}</div>
            </div>
          </div>
          
          <div className="elContactUs">
            <div className="font-DINAlternateBold" style={style_elContactUs}>
              <div>{this.context.locStrings.privacypolicy_changescopy_242395}</div>
            </div>
          </div>
          
          <div className="elTextCopy12">
            <div className="font-AppleSystemUIFont" style={style_elTextCopy12}>
              <div>{this.context.locStrings.privacypolicy_textcopy12_310992}</div>
            </div>
          </div>
          
          <div className="elEmailLink">
            <div className="font-AppleSystemUIFont" style={style_elEmailLink} onClick={this.onClick_elEmailLink} >
              <div>{this.context.locStrings.privacypolicy_admoblinkcopy_876816}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elHeaderComponent">
              <HeaderComponent ref={(el)=> this._elHeaderComponent = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
