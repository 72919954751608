// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_entData_row0_startplaying from './images/datasheet_entData_row0_startplaying.png';
import datasheet_entData_row0_picture from './images/datasheet_entData_row0_picture.png';
import datasheet_entData_row0_appleBadge from './images/datasheet_entData_row0_appleBadge.png';
import datasheet_entData_row0_rules from './images/datasheet_entData_row0_rules.png';
import datasheet_entData_row0_appleQRcode from './images/datasheet_entData_row0_appleQRcode.png';
import datasheet_entData_row0_certificate from './images/datasheet_entData_row0_certificate.png';
import datasheet_entData_row0_gameend from './images/datasheet_entData_row0_gameend.png';
import datasheet_entData_row0_havefun from './images/datasheet_entData_row0_havefun.png';
import datasheet_entData_row0_googleBadge from './images/datasheet_entData_row0_googleBadge.png';
import datasheet_entData_row0_googleQRcode from './images/datasheet_entData_row0_googleQRcode.png';
import datasheet_entData_row1_startplaying from './images/datasheet_entData_row1_startplaying.png';
import datasheet_entData_row1_picture from './images/datasheet_entData_row1_picture.png';
import datasheet_entData_row1_appleBadge from './images/datasheet_entData_row1_appleBadge.png';
import datasheet_entData_row1_rules from './images/datasheet_entData_row1_rules.png';
import datasheet_entData_row1_appleQRcode from './images/datasheet_entData_row1_appleQRcode.png';
import datasheet_entData_row1_certificate from './images/datasheet_entData_row1_certificate.png';
import datasheet_entData_row1_gameend from './images/datasheet_entData_row1_gameend.png';
import datasheet_entData_row1_havefun from './images/datasheet_entData_row1_havefun.png';
import datasheet_entData_row1_googleBadge from './images/datasheet_entData_row1_googleBadge.png';
import datasheet_entData_row1_googleQRcode from './images/datasheet_entData_row1_googleQRcode.png';
import datasheet_entData_row2_startplaying from './images/datasheet_entData_row2_startplaying.png';
import datasheet_entData_row2_picture from './images/datasheet_entData_row2_picture.png';
import datasheet_entData_row2_appleBadge from './images/datasheet_entData_row2_appleBadge.png';
import datasheet_entData_row2_rules from './images/datasheet_entData_row2_rules.png';
import datasheet_entData_row2_appleQRcode from './images/datasheet_entData_row2_appleQRcode.png';
import datasheet_entData_row2_certificate from './images/datasheet_entData_row2_certificate.png';
import datasheet_entData_row2_gameend from './images/datasheet_entData_row2_gameend.png';
import datasheet_entData_row2_havefun from './images/datasheet_entData_row2_havefun.png';
import datasheet_entData_row2_googleBadge from './images/datasheet_entData_row2_googleBadge.png';
import datasheet_entData_row2_googleQRcode from './images/datasheet_entData_row2_googleQRcode.png';

export default class DataSheet_entData extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_entData_row0_startplaying;
    item['rulesText'] = "Check out the Rules";
    item['title'] = "Seinfeld Trivia [Show About Nothing]";
    item['picture'] = datasheet_entData_row0_picture;
    item['appleBadge'] = datasheet_entData_row0_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.seinfeld";
    item['rules'] = datasheet_entData_row0_rules;
    item['appleQRcode'] = datasheet_entData_row0_appleQRcode;
    item['certificate'] = datasheet_entData_row0_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_entData_row0_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/id1547375933";
    item['have-fun'] = datasheet_entData_row0_havefun;
    item['long description'] = "Seinfeld fans, \"walk down the memory lane\" with this fun and simple Trivia. Enjoy the clips for both wrong and right answers.\nThere are 100 questions in all and you can generate a Seinfeld fan certificate in the end. Share this certificate with other Seinfeld fans and challenge them to prove that they are bigger fans than you.";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_entData_row0_googleBadge;
    item['googleQRcode'] = datasheet_entData_row0_googleQRcode;
    item['description'] = "Show About Nothing Trivia";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_entData_row1_startplaying;
    item['rulesText'] = "Check out the Rules";
    item['title'] = "Unfortunate Events Trivia";
    item['picture'] = datasheet_entData_row1_picture;
    item['appleBadge'] = datasheet_entData_row1_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.unfortunateevents";
    item['rules'] = datasheet_entData_row1_rules;
    item['appleQRcode'] = datasheet_entData_row1_appleQRcode;
    item['certificate'] = datasheet_entData_row1_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_entData_row1_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/unfortunate-events-trivia/id1585577310";
    item['have-fun'] = datasheet_entData_row1_havefun;
    item['long description'] = "Unfortunate Events fans, \"walk down the memory lane\" with this fun and simple Trivia. Enjoy the clips for both wrong and right answers.\nThere are 100 questions in all and you can generate a fan certificate in the end. Share this certificate with other fans and challenge them to prove that they are bigger fans than you.";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_entData_row1_googleBadge;
    item['googleQRcode'] = datasheet_entData_row1_googleQRcode;
    item['description'] = "Unfortunate Events Trivia";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_entData_row2_startplaying;
    item['rulesText'] = "Check out the Rules";
    item['title'] = "Star Wars Trivia [Space Wars Trivia]";
    item['picture'] = datasheet_entData_row2_picture;
    item['appleBadge'] = datasheet_entData_row2_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.starwars";
    item['rules'] = datasheet_entData_row2_rules;
    item['appleQRcode'] = datasheet_entData_row2_appleQRcode;
    item['certificate'] = datasheet_entData_row2_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_entData_row2_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/space-wars-trivia/id1590701815";
    item['have-fun'] = datasheet_entData_row2_havefun;
    item['long description'] = "Check your Star Wars knowledge.\nThere are 100 questions in all and you can generate a fan certificate in the end. Share this certificate with other fans and challenge them to prove that they are bigger fans than you.";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_entData_row2_googleBadge;
    item['googleQRcode'] = datasheet_entData_row2_googleQRcode;
    item['description'] = "Star Wars Trivia";
    item.key = key++;
  }

}
