// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_musicData_row0_startplaying from './images/datasheet_musicData_row0_startplaying.png';
import datasheet_musicData_row0_picture from './images/datasheet_musicData_row0_picture.png';
import datasheet_musicData_row0_appleBadge from './images/datasheet_musicData_row0_appleBadge.png';
import datasheet_musicData_row0_rules from './images/datasheet_musicData_row0_rules.png';
import datasheet_musicData_row0_appleQRcode from './images/datasheet_musicData_row0_appleQRcode.png';
import datasheet_musicData_row0_certificate from './images/datasheet_musicData_row0_certificate.png';
import datasheet_musicData_row0_gameend from './images/datasheet_musicData_row0_gameend.png';
import datasheet_musicData_row0_havefun from './images/datasheet_musicData_row0_havefun.png';
import datasheet_musicData_row0_googleBadge from './images/datasheet_musicData_row0_googleBadge.png';
import datasheet_musicData_row0_googleQRcode from './images/datasheet_musicData_row0_googleQRcode.png';

export default class DataSheet_musicData extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_musicData_row0_startplaying;
    item['rulesText'] = "Check out the Rules";
    item['title'] = "Classical Music Quiz [Orchestra Music Quiz]";
    item['picture'] = datasheet_musicData_row0_picture;
    item['appleBadge'] = datasheet_musicData_row0_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.music";
    item['rules'] = datasheet_musicData_row0_rules;
    item['appleQRcode'] = datasheet_musicData_row0_appleQRcode;
    item['certificate'] = datasheet_musicData_row0_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_musicData_row0_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/orchestra-music-quiz/id1582551156";
    item['have-fun'] = datasheet_musicData_row0_havefun;
    item['long description'] = "Challenge your mind with simple and fun Music trivia and see where you stand. There are 100 questions/levels sprinkled with some interesting puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most.\nWatch solutions for better understanding of answers.";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_musicData_row0_googleBadge;
    item['googleQRcode'] = datasheet_musicData_row0_googleQRcode;
    item['description'] = "Classical Music Quiz";
    item.key = key++;
  }

}
