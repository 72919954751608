import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CategoriesTabs from './CategoriesTabs';
import ImageHome from './ImageHome';
import AdvItem from './AdvItem';
import EntItem from './EntItem';
import Footer from './Footer';
import HeaderComponent from './HeaderComponent';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class AboutUsScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  
  onClick_elEllipse = async () => {
    // Go to screen 'Top Scorer'
    this.context.appActions.goToScreen('topScorer', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elPrivacyPolicyButton = async () => {
    // Go to screen 'Privacy Policy'
    this.context.appActions.goToScreen('privacyPolicy', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elEmailLink = async () => {
    window.open('mailto:support@it365solutionsinc.com', '_blank');
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_musicData = this.context.dataSheets['musicData'];
    // eslint-disable-next-line
    const dataSheet_entData = this.context.dataSheets['entData'];
    // eslint-disable-next-line
    const dataSheet_listData = this.context.dataSheets['listData'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#c7ebf6',
     };
    const style_elMission = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elMissionStatement = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elMathApps = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('listData').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elEntertainmentApps = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'entertainmentList'.
    let items_entertainmentList = [];
    let listComps_entertainmentList = {};
    items_entertainmentList = items_entertainmentList.concat(this.context.appActions.getDataSheet('entData').items);
    this._elEntertainmentList_items = [];
    
    const style_elEntertainmentList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elMusicApps = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'musicList'.
    let items_musicList = [];
    let listComps_musicList = {};
    items_musicList = items_musicList.concat(this.context.appActions.getDataSheet('musicData').items);
    this._elMusicList_items = [];
    
    const style_elMusicList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_elEllipse = {
      background: 'rgba(2, 35, 112, 1.000)',
      borderRadius: '50%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText2 = {
      fontSize: 25.0,
      color: '#feffff',
      textAlign: 'center',
     };
    const style_elComingSoon = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elComingSoonText = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elPrivacyPolicy = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elPrivacyPolicyLink = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    
    const style_elPrivacyPolicyButton = {
      display: 'block',
      fontSize: 20.0,
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elContactUs = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elContactDetails = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elEmailLink = {
      fontSize: 25.0,
      color: '#0432ff',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen AboutUsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elCategoriesTabs">
            <div>
              <CategoriesTabs ref={(el)=> this._elCategoriesTabs = el} />
            </div>
          </div>
          
          <div className="elMission">
            <div className="font-silom" style={style_elMission}>
              <div>{this.context.locStrings.aboutus_text_543929}</div>
            </div>
          </div>
          
          <div className="elMissionStatement">
            <div className="font-symbol" style={style_elMissionStatement}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.aboutus_text2_162420.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elSpacer3">
            <div />
          </div>
          
          <div className="hasNestedComps elImageHome">
            <div>
              <ImageHome ref={(el)=> this._elImageHome = el} />
            </div>
          </div>
          
          <div className="elMathApps">
            <div className="font-silom" style={style_elMathApps}>
              <div>{this.context.locStrings.aboutus_missioncopy_149750}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <div style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <AdvItem dataSheetId={'listData'} dataSheetRow={row} {...{ 'picture': row['picture'], 'description': row['description'], 'googleBadge': row['googleBadge'], 'googleLink': row['googleLink'], 'appleBadge': row['appleBadge'], 'appleLink': row['appleLink'], 'googleQRcode': row['googleQRcode'], 'appleQRcode': row['appleQRcode'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </div>
          </div>
          
          <div className="elEntertainmentApps">
            <div className="font-silom" style={style_elEntertainmentApps}>
              <div>{this.context.locStrings.aboutus_mathappscopy_640104}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elEntertainmentList">
            <div style={style_elEntertainmentList}>
              {items_entertainmentList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_entertainmentList[row._componentId]
                    : <EntItem dataSheetId={'entData'} dataSheetRow={row} {...{ 'picture': row['picture'], 'googleBadge': row['googleBadge'], 'googleLink': row['googleLink'], 'appleBadge': row['appleBadge'], 'appleLink': row['appleLink'], 'title': row['title'], 'appleQRcode': row['appleQRcode'], 'googleQRcode': row['googleQRcode'], }} ref={(el) => {if (el) this._elEntertainmentList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elEntertainmentList_endMarker = el} />
            </div>
          </div>
          
          <div className="elMusicApps">
            <div className="font-silom" style={style_elMusicApps}>
              <div>{this.context.locStrings.aboutus_entertainmentappscopy_35050}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elMusicList">
            <div style={style_elMusicList}>
              {items_musicList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_musicList[row._componentId]
                    : <EntItem dataSheetId={'musicData'} dataSheetRow={row} {...{ 'picture': row['picture'], 'googleBadge': row['googleBadge'], 'googleLink': row['googleLink'], 'appleBadge': row['appleBadge'], 'appleLink': row['appleLink'], 'title': row['title'], 'appleQRcode': row['appleQRcode'], 'googleQRcode': row['googleQRcode'], }} ref={(el) => {if (el) this._elMusicList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elMusicList_endMarker = el} />
            </div>
          </div>
          
          <div className="elEllipse">
            <div style={style_elEllipse} onClick={this.onClick_elEllipse}  />
          </div>
          
          <div className="elText2">
            <div className="systemFontBold" style={style_elText2}>
              <div>{this.context.locStrings.aboutus_text2_414556}</div>
            </div>
          </div>
          
          <div className="elComingSoon">
            <div className="font-silom" style={style_elComingSoon}>
              <div>{this.context.locStrings.aboutus_text5_14128}</div>
            </div>
          </div>
          
          <div className="elComingSoonText">
            <div className="font-symbol" style={style_elComingSoonText}>
              <div>{this.context.locStrings.aboutus_text6_353978}</div>
            </div>
          </div>
          
          <div className="elPrivacyPolicy">
            <div className="font-silom" style={style_elPrivacyPolicy}>
              <div>{this.context.locStrings.aboutus_textcopy_327016}</div>
            </div>
          </div>
          
          <div className="elPrivacyPolicyLink">
            <div className="font-symbol" style={style_elPrivacyPolicyLink}>
              <div>{this.context.locStrings.aboutus_textcopy2_611141}</div>
            </div>
          </div>
          
          <div className="elPrivacyPolicyButton">
            <Button className="font-robotoRegular" style={style_elPrivacyPolicyButton}  color="accent" onClick={this.onClick_elPrivacyPolicyButton} >
              {this.context.locStrings.aboutus_button2_758042}
            </Button>
          </div>
          
          <div className="elContactUs">
            <div className="font-silom" style={style_elContactUs}>
              <div>{this.context.locStrings.aboutus_text7_18884}</div>
            </div>
          </div>
          
          <div className="elContactDetails">
            <div className="font-symbol" style={style_elContactDetails}>
              <div>{this.context.locStrings.aboutus_text8_377728}</div>
            </div>
          </div>
          
          <div className="elEmailLink">
            <div className="font-symbol" style={style_elEmailLink} onClick={this.onClick_elEmailLink} >
              <div>{this.context.locStrings.aboutus_emaillink_167970}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} />
            </div>
          </div>
          
          <div className="elSpacerCopy2">
            <div />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elHeaderComponent">
              <HeaderComponent ref={(el)=> this._elHeaderComponent = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
