import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class CategoriesTabs extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elMathApps = async () => {
    // Go to screen 'Math Apps'
    this.context.appActions.goToScreen('mathApps', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elEntertainment = async () => {
    // Go to screen 'Entertainment Apps'
    this.context.appActions.goToScreen('entertainmentApps', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elMusic = async () => {
    // Go to screen 'Music Apps'
    this.context.appActions.goToScreen('musicApps', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elQAServices = async () => {
    // Go to screen 'QA Services'
    this.context.appActions.goToScreen('QAServices', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#88f4fb',
     };
    
    const style_elMathApps = {
      display: 'block',
      fontSize: 18.0,
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elEntertainment = {
      display: 'block',
      fontSize: 18.0,
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elMusic = {
      display: 'block',
      fontSize: 18.0,
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elQAServices = {
      display: 'block',
      fontSize: 18.0,
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="CategoriesTabs">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elMathApps">
            <Button className="font-SFUITextBold" style={style_elMathApps}  color="accent" onClick={this.onClick_elMathApps} >
              {this.context.locStrings.categoriestabs_button_969308}
            </Button>
          </div>
          
          <div className="elEntertainment">
            <Button className="font-SFUITextBold" style={style_elEntertainment}  color="accent" onClick={this.onClick_elEntertainment} >
              {this.context.locStrings.categoriestabs_mathappscopy_741834}
            </Button>
          </div>
          
          <div className="elMusic">
            <Button className="font-SFUITextBold" style={style_elMusic}  color="accent" onClick={this.onClick_elMusic} >
              {this.context.locStrings.categoriestabs_entertainmentcopy_665372}
            </Button>
          </div>
          
          <div className="elQAServices">
            <Button className="font-SFUITextBold" style={style_elQAServices}  color="accent" onClick={this.onClick_elQAServices} >
              {this.context.locStrings.categoriestabs_musiccopy_79617}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
