import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elBanner982162_1920 from './images/ImageTesting_elBanner982162_1920_187959.jpg';

export default class ImageTesting extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#4cf5f8',
     };
    const style_elBanner982162_1920 = {
      height: 'auto',
     };
    
    return (
      <div className="ImageTesting">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elBanner982162_1920">
            <img style={style_elBanner982162_1920} src={img_elBanner982162_1920} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
