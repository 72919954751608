import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import ListItem from './ListItem';
import ListEntItem from './ListEntItem';
import ListMusicItem from './ListMusicItem';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Drawer3 extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 4) --- 
  
  onClick_state0_elHome615763 = async () => {
    // Go to screen 'About Us'
    this.context.appActions.goToScreen('aboutUs', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state0_elMathApps = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  onClick_state0_elEntApps = async () => {
    this.setState({visualStateIndexOverride: 2})
  
  }
  
  
  onClick_state0_elMusicApps = async () => {
    this.setState({visualStateIndexOverride: 3})
  
  }
  
  
  onClick_state0_elTopScorers = async () => {
    // Go to screen 'Top Scorer'
    this.context.appActions.goToScreen('topScorer', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state0_elPrivacyPolicy = async () => {
    // Go to screen 'Privacy Policy'
    this.context.appActions.goToScreen('privacyPolicy', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state0_elQAServices = async () => {
    // Go to screen 'QA Services'
    this.context.appActions.goToScreen('QAServices', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  renderState0() {
    const style_state0_elBackground42840 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground42840_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#1f90be',
      filter: 'drop-shadow(0.0px 1.0px 0px rgba(20, 23, 255, 0.7500))',
      overflow: 'visible',
     };
    const style_state0_elBackgroundWhite612569 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackgroundWhite612569_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    
    const style_state0_elHome615763 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#032269',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elMathApps = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elEntApps = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elMusicApps = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elTopScorers = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002573',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elPrivacyPolicy = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002573',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elQAServices = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002573',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Drawer3">
        <div className="background">
          <div className="containerMinHeight state0_elBackground42840" style={style_state0_elBackground42840_outer}>
            <div style={style_state0_elBackground42840} />
          </div>
          
          <div className="containerMinHeight state0_elBackgroundWhite612569" style={style_state0_elBackgroundWhite612569_outer}>
            <div style={style_state0_elBackgroundWhite612569} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elHome615763">
            <Button className="systemFontRegular" style={style_state0_elHome615763} onClick={this.onClick_state0_elHome615763} >
              {this.context.locStrings.drawer2_buttoncopy5_615763}
            </Button>
          </div>
          
          <div className="state0_elMathApps">
            <Button className="systemFontRegular" style={style_state0_elMathApps} onClick={this.onClick_state0_elMathApps} >
              {this.context.locStrings.drawer2_buttoncopy_149020}
            </Button>
          </div>
          
          <div className="state0_elEntApps">
            <Button className="systemFontRegular" style={style_state0_elEntApps} onClick={this.onClick_state0_elEntApps} >
              {this.context.locStrings.drawer2_mathappscopy_491807}
            </Button>
          </div>
          
          <div className="state0_elMusicApps">
            <Button className="systemFontRegular" style={style_state0_elMusicApps} onClick={this.onClick_state0_elMusicApps} >
              {this.context.locStrings.drawer3_entappscopy_1029088}
            </Button>
          </div>
          
          <div className="state0_elTopScorers">
            <Button className="systemFontRegular" style={style_state0_elTopScorers} onClick={this.onClick_state0_elTopScorers} >
              {this.context.locStrings.drawer2_privacypolicycopy_347349}
            </Button>
          </div>
          
          <div className="state0_elPrivacyPolicy">
            <Button className="systemFontRegular" style={style_state0_elPrivacyPolicy} onClick={this.onClick_state0_elPrivacyPolicy} >
              {this.context.locStrings.drawer2_buttoncopy2_867295}
            </Button>
          </div>
          
          <div className="state0_elQAServices">
            <Button className="systemFontRegular" style={style_state0_elQAServices} onClick={this.onClick_state0_elQAServices} >
              {this.context.locStrings.drawer3_privacypolicycopy_891467}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 4) --- 
  
  onClick_state1_elHome615763 = async () => {
    // Go to screen 'About Us'
    this.context.appActions.goToScreen('aboutUs', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state1_elMathAppsExp = async () => {
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  
  onClick_state1_elEntAppsState2 = async () => {
    this.setState({visualStateIndexOverride: 2})
  
  }
  
  
  onClick_state1_elMusicAppsState2 = async () => {
    this.setState({visualStateIndexOverride: 3})
  
  }
  
  
  onClick_state1_elTopScorersState2 = async () => {
    // Go to screen 'Top Scorer'
    this.context.appActions.goToScreen('topScorer', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state1_elPrivacyPolicyState2 = async () => {
    // Go to screen 'Privacy Policy'
    this.context.appActions.goToScreen('privacyPolicy', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state1_elQAServicesState2 = async () => {
    // Go to screen 'QA Services'
    this.context.appActions.goToScreen('QAServices', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  renderState1() {
    // eslint-disable-next-line
    const dataSheet_listData = this.context.appActions.getDataSheet('listData');
    const style_state1_elBackground42840 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground42840_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#1f90be',
      filter: 'drop-shadow(0.0px 1.0px 0px rgba(20, 23, 255, 0.7500))',
      overflow: 'visible',
     };
    const style_state1_elBackgroundWhite612569 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackgroundWhite612569_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    
    const style_state1_elHome615763 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#032269',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elMathAppsExp = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('listData').items);
    this._state1_elList_items = [];
    
    const style_state1_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state1_elEntAppsState2 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elMusicAppsState2 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elTopScorersState2 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elPrivacyPolicyState2 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elQAServicesState2 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Drawer3">
        <div className="background">
          <div className="containerMinHeight state1_elBackground42840" style={style_state1_elBackground42840_outer}>
            <div style={style_state1_elBackground42840} />
          </div>
          
          <div className="containerMinHeight state1_elBackgroundWhite612569" style={style_state1_elBackgroundWhite612569_outer}>
            <div style={style_state1_elBackgroundWhite612569} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elHome615763">
            <Button className="systemFontRegular" style={style_state1_elHome615763} onClick={this.onClick_state1_elHome615763} >
              {this.context.locStrings.drawer2_buttoncopy5_615763}
            </Button>
          </div>
          
          <div className="state1_elMathAppsExp">
            <Button className="systemFontRegular" style={style_state1_elMathAppsExp} onClick={this.onClick_state1_elMathAppsExp} >
              {this.context.locStrings.drawer2_buttoncopy4_450442}
            </Button>
          </div>
          
          <div className="hasNestedComps state1_elList">
            <div style={style_state1_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <ListItem dataSheetId={'listData'} dataSheetRow={row} {...{ 'title': row['title'], }} ref={(el) => {if (el) this._state1_elList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList_endMarker = el} />
            </div>
          </div>
          
          <div className="state1_elEntAppsState2">
            <Button className="systemFontRegular" style={style_state1_elEntAppsState2} onClick={this.onClick_state1_elEntAppsState2} >
              {this.context.locStrings.drawer2_entappscopy_782263}
            </Button>
          </div>
          
          <div className="state1_elMusicAppsState2">
            <Button className="systemFontRegular" style={style_state1_elMusicAppsState2} onClick={this.onClick_state1_elMusicAppsState2} >
              {this.context.locStrings.drawer3_entappsstate2copy_999375}
            </Button>
          </div>
          
          <div className="state1_elTopScorersState2">
            <Button className="systemFontRegular" style={style_state1_elTopScorersState2} onClick={this.onClick_state1_elTopScorersState2} >
              {this.context.locStrings.drawer2_privacypolicystatecopy_898471}
            </Button>
          </div>
          
          <div className="state1_elPrivacyPolicyState2">
            <Button className="systemFontRegular" style={style_state1_elPrivacyPolicyState2} onClick={this.onClick_state1_elPrivacyPolicyState2} >
              {this.context.locStrings.drawer2_buttoncopy3_61755}
            </Button>
          </div>
          
          <div className="state1_elQAServicesState2">
            <Button className="systemFontRegular" style={style_state1_elQAServicesState2} onClick={this.onClick_state1_elQAServicesState2} >
              {this.context.locStrings.drawer3_privacypolicystate2copy_218166}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 4) --- 
  
  onClick_state2_elHome615763 = async () => {
    // Go to screen 'About Us'
    this.context.appActions.goToScreen('aboutUs', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state2_elMathAppsState3 = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  onClick_state2_elEntAppsExp = async () => {
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  
  onClick_state2_elMusicAppsState3 = async () => {
    this.setState({visualStateIndexOverride: 3})
  
  }
  
  
  onClick_state2_elTopScorersState3 = async () => {
    // Go to screen 'Top Scorer'
    this.context.appActions.goToScreen('topScorer', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state2_elPrivacyPolicyState3 = async () => {
    // Go to screen 'Privacy Policy'
    this.context.appActions.goToScreen('privacyPolicy', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state2_elQAServicesState3 = async () => {
    // Go to screen 'QA Services'
    this.context.appActions.goToScreen('QAServices', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  renderState2() {
    // eslint-disable-next-line
    const dataSheet_entData = this.context.appActions.getDataSheet('entData');
    // eslint-disable-next-line
    const dataSheet_listData = this.context.appActions.getDataSheet('listData');
    const style_state2_elBackground42840 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackground42840_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#1f90be',
      filter: 'drop-shadow(0.0px 1.0px 0px rgba(20, 23, 255, 0.7500))',
      overflow: 'visible',
     };
    const style_state2_elBackgroundWhite612569 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackgroundWhite612569_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    
    const style_state2_elHome615763 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#032269',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elMathAppsState3 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elEntAppsExp = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'Ent list'.
    let items_entList = [];
    let listComps_entList = {};
    items_entList = items_entList.concat(this.context.appActions.getDataSheet('entData').items);
    this._state2_elEntList_items = [];
    
    const style_state2_elEntList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state2_elMusicAppsState3 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elTopScorersState3 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elPrivacyPolicyState3 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state2_elQAServicesState3 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Drawer3">
        <div className="background">
          <div className="containerMinHeight state2_elBackground42840" style={style_state2_elBackground42840_outer}>
            <div style={style_state2_elBackground42840} />
          </div>
          
          <div className="containerMinHeight state2_elBackgroundWhite612569" style={style_state2_elBackgroundWhite612569_outer}>
            <div style={style_state2_elBackgroundWhite612569} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state2_elHome615763">
            <Button className="systemFontRegular" style={style_state2_elHome615763} onClick={this.onClick_state2_elHome615763} >
              {this.context.locStrings.drawer2_buttoncopy5_615763}
            </Button>
          </div>
          
          <div className="state2_elMathAppsState3">
            <Button className="systemFontRegular" style={style_state2_elMathAppsState3} onClick={this.onClick_state2_elMathAppsState3} >
              {this.context.locStrings.drawer2_mathappscopy2_72220}
            </Button>
          </div>
          
          <div className="state2_elEntAppsExp">
            <Button className="systemFontRegular" style={style_state2_elEntAppsExp} onClick={this.onClick_state2_elEntAppsExp} >
              {this.context.locStrings.drawer2_mathappsexpcopy_674270}
            </Button>
          </div>
          
          <div className="hasNestedComps state2_elEntList">
            <ul style={style_state2_elEntList}>
              {items_entList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_entList[row._componentId]
                    : <ListEntItem dataSheetId={'entData'} dataSheetRow={row} {...{ 'title': row['title'], }} ref={(el) => {if (el) this._state2_elEntList_items.push(el)}} />;
                return (<li key={row.key || index}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state2_elEntList_endMarker = el} />
            </ul>
          </div>
          
          <div className="state2_elMusicAppsState3">
            <Button className="systemFontRegular" style={style_state2_elMusicAppsState3} onClick={this.onClick_state2_elMusicAppsState3} >
              {this.context.locStrings.drawer3_mathappsstate3copy_304872}
            </Button>
          </div>
          
          <div className="state2_elTopScorersState3">
            <Button className="systemFontRegular" style={style_state2_elTopScorersState3} onClick={this.onClick_state2_elTopScorersState3} >
              {this.context.locStrings.drawer2_topscorersstatecopy_427721}
            </Button>
          </div>
          
          <div className="state2_elPrivacyPolicyState3">
            <Button className="systemFontRegular" style={style_state2_elPrivacyPolicyState3} onClick={this.onClick_state2_elPrivacyPolicyState3} >
              {this.context.locStrings.drawer2_privacypolicystatecopy_514833}
            </Button>
          </div>
          
          <div className="state2_elQAServicesState3">
            <Button className="systemFontRegular" style={style_state2_elQAServicesState3} onClick={this.onClick_state2_elQAServicesState3} >
              {this.context.locStrings.drawer3_privacypolicystate3copy_384422}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 4) --- 
  
  onClick_state3_elHome615763 = async () => {
    // Go to screen 'About Us'
    this.context.appActions.goToScreen('aboutUs', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state3_elMathAppsState4 = async () => {
    this.setState({visualStateIndexOverride: 1})
  
  }
  
  
  onClick_state3_elEntAppsState4 = async () => {
    this.setState({visualStateIndexOverride: 2})
  
  }
  
  
  onClick_state3_elMusicAppsExp = async () => {
    this.setState({visualStateIndexOverride: 0})
  
  }
  
  
  
  onClick_state3_elTopScorersState4 = async () => {
    // Go to screen 'Top Scorer'
    this.context.appActions.goToScreen('topScorer', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state3_elPrivacyPolicyState4 = async () => {
    // Go to screen 'Privacy Policy'
    this.context.appActions.goToScreen('privacyPolicy', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  onClick_state3_elQAServicesState4 = async () => {
    // Go to screen 'QA Services'
    this.context.appActions.goToScreen('QAServices', this.context.baseScreenId, { transitionId: 'fadeIn', closeDrawer: null });
  
  }
  
  
  renderState3() {
    // eslint-disable-next-line
    const dataSheet_musicData = this.context.appActions.getDataSheet('musicData');
    // eslint-disable-next-line
    const dataSheet_entData = this.context.appActions.getDataSheet('entData');
    // eslint-disable-next-line
    const dataSheet_listData = this.context.appActions.getDataSheet('listData');
    const style_state3_elBackground42840 = {
      width: '100%',
      height: '100%',
     };
    const style_state3_elBackground42840_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#1f90be',
      filter: 'drop-shadow(0.0px 1.0px 0px rgba(20, 23, 255, 0.7500))',
      overflow: 'visible',
     };
    const style_state3_elBackgroundWhite612569 = {
      width: '100%',
      height: '100%',
     };
    const style_state3_elBackgroundWhite612569_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#feffff',
     };
    
    const style_state3_elHome615763 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#032269',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state3_elMathAppsState4 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state3_elEntAppsState4 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state3_elMusicAppsExp = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'Music list'.
    let items_musicList = [];
    let listComps_musicList = {};
    items_musicList = items_musicList.concat(this.context.appActions.getDataSheet('musicData').items);
    this._state3_elMusicList_items = [];
    
    const style_state3_elMusicList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_state3_elTopScorersState4 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state3_elPrivacyPolicyState4 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state3_elQAServicesState4 = {
      display: 'block',
      fontSize: 20.0,
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#002470',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Drawer3">
        <div className="background">
          <div className="containerMinHeight state3_elBackground42840" style={style_state3_elBackground42840_outer}>
            <div style={style_state3_elBackground42840} />
          </div>
          
          <div className="containerMinHeight state3_elBackgroundWhite612569" style={style_state3_elBackgroundWhite612569_outer}>
            <div style={style_state3_elBackgroundWhite612569} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="state3_elHome615763">
            <Button className="systemFontRegular" style={style_state3_elHome615763} onClick={this.onClick_state3_elHome615763} >
              {this.context.locStrings.drawer2_buttoncopy5_615763}
            </Button>
          </div>
          
          <div className="state3_elMathAppsState4">
            <Button className="systemFontRegular" style={style_state3_elMathAppsState4} onClick={this.onClick_state3_elMathAppsState4} >
              {this.context.locStrings.drawer3_mathappsstate3copy_315875}
            </Button>
          </div>
          
          <div className="state3_elEntAppsState4">
            <Button className="systemFontRegular" style={style_state3_elEntAppsState4} onClick={this.onClick_state3_elEntAppsState4} >
              {this.context.locStrings.drawer3_entappsstate2copy_767725}
            </Button>
          </div>
          
          <div className="state3_elMusicAppsExp">
            <Button className="systemFontRegular" style={style_state3_elMusicAppsExp} onClick={this.onClick_state3_elMusicAppsExp} >
              {this.context.locStrings.drawer3_entappsexpcopy_82052}
            </Button>
          </div>
          
          <div className="hasNestedComps state3_elMusicList">
            <ul style={style_state3_elMusicList}>
              {items_musicList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_musicList[row._componentId]
                    : <ListMusicItem dataSheetId={'musicData'} dataSheetRow={row} {...{ 'title': row['title'], }} ref={(el) => {if (el) this._state3_elMusicList_items.push(el)}} />;
                return (<li key={row.key || index}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state3_elMusicList_endMarker = el} />
            </ul>
          </div>
          
          <div className="state3_elTopScorersState4">
            <Button className="systemFontRegular" style={style_state3_elTopScorersState4} onClick={this.onClick_state3_elTopScorersState4} >
              {this.context.locStrings.drawer3_topscorersstate3copy_23955}
            </Button>
          </div>
          
          <div className="state3_elPrivacyPolicyState4">
            <Button className="systemFontRegular" style={style_state3_elPrivacyPolicyState4} onClick={this.onClick_state3_elPrivacyPolicyState4} >
              {this.context.locStrings.drawer3_privacypolicystate3copy_155166}
            </Button>
          </div>
          
          <div className="state3_elQAServicesState4">
            <Button className="systemFontRegular" style={style_state3_elQAServicesState4} onClick={this.onClick_state3_elQAServicesState4} >
              {this.context.locStrings.drawer3_privacypolicystate4copy_65961}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
    }
  }
  
}
