import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elPicture from './images/EntItem_elPicture_914452.jpg';
import img_elPictureGoogleBadge from './images/EntDetailsScreen_elGooglePlayStore_926386.png';
import img_elPictureAppleBadge from './images/EntItem_elPictureAppleBadge_514756.jpg';
import img_elGoogleQRcode from './images/EntItem_elGoogleQRcode_51593.jpg';
import img_elAppleQRcode from './images/EntItem_elAppleQRcode_928135.jpg';

export default class AdvItem extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // picture, description, googleBadge, googleLink, appleBadge, appleLink, googleQRcode, appleQRcode

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elPicture = async () => {
    // Go to screen 'Math Details'
    this.context.appActions.goToScreen('mathDetails', this.context.baseScreenId, { ...this.props, transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elPictureGoogleBadge = async () => {
    window.open(this.props.googleLink, '_blank');
  
  }
  
  
  onClick_elPictureAppleBadge = async () => {
    window.open(this.props.appleLink, '_blank');
  
  }
  
  
  onClick_elGoogleQRcode = async () => {
    window.open(this.props.googleLink, '_blank');
  
  }
  
  
  onClick_elAppleQRcode = async () => {
    window.open(this.props.appleLink, '_blank');
  
  }
  
  
  onClick_elAppleHotspot = async () => {
    window.open(this.props.appleLink, '_blank');
  
  }
  
  
  onClick_elGoogleHotspot = async () => {
    window.open(this.props.googleLink, '_blank');
  
  }
  
  
  render() {
    
    const style_elClickableBg = {
      background: 'rgba(255, 255, 255, 0.000)',
     };
    
    const value_picture = this.props.picture;
    
    const style_elPicture = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_title = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.description);
    
    const style_elTitle = {
      fontSize: 18.0,
      color: 'black',
      textAlign: 'left',
     };
    
    const value_pictureGoogleBadge = this.props.googleBadge;
    
    const style_elPictureGoogleBadge = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_pictureAppleBadge = this.props.appleBadge;
    
    const style_elPictureAppleBadge = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_googleQRcode = this.props.googleQRcode;
    
    const style_elGoogleQRcode = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_appleQRcode = this.props.appleQRcode;
    
    const style_elAppleQRcode = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elAppleHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elGoogleHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AdvItem">
        <div className="background">
          <div className="elClickableBg" style={style_elClickableBg} />
        </div>
        
        <div className="layoutFlow">
          <div className="elPicture">
            <img style={style_elPicture} src={this.context.appActions.createImageUrlFromProp(value_picture)} alt="" onClick={this.onClick_elPicture}  />
          </div>
          
          <div className="elTitle">
            <div className="font-futuraBold" style={style_elTitle}>
              <div>{value_title}</div>
            </div>
          </div>
          
          <div className="elPictureGoogleBadge">
            <img style={style_elPictureGoogleBadge} src={this.context.appActions.createImageUrlFromProp(value_pictureGoogleBadge)} alt="" onClick={this.onClick_elPictureGoogleBadge}  />
          </div>
          
          <div className="elPictureAppleBadge">
            <img style={style_elPictureAppleBadge} src={this.context.appActions.createImageUrlFromProp(value_pictureAppleBadge)} alt="" onClick={this.onClick_elPictureAppleBadge}  />
          </div>
          
          <div className="elGoogleQRcode">
            <img style={style_elGoogleQRcode} src={this.context.appActions.createImageUrlFromProp(value_googleQRcode)} alt="" onClick={this.onClick_elGoogleQRcode}  />
          </div>
          
          <div className="elAppleQRcode">
            <img style={style_elAppleQRcode} src={this.context.appActions.createImageUrlFromProp(value_appleQRcode)} alt="" onClick={this.onClick_elAppleQRcode}  />
          </div>
        </div>
        
        <div className="foreground">
          <div className="actionFont elAppleHotspot" style={style_elAppleHotspot} onClick={this.onClick_elAppleHotspot}  />
          <div className="actionFont elGoogleHotspot" style={style_elGoogleHotspot} onClick={this.onClick_elGoogleHotspot}  />
        </div>
      </div>
    )
  }
  
}
