import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Social from './Social';

export default class Footer extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elAboutUs = async () => {
    // Go to screen 'About Us'
    this.context.appActions.goToScreen('aboutUs', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elMathApps = async () => {
    // Go to screen 'Math Apps'
    this.context.appActions.goToScreen('mathApps', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elEntertainment = async () => {
    // Go to screen 'Entertainment Apps'
    this.context.appActions.goToScreen('entertainmentApps', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elMusic = async () => {
    // Go to screen 'Music Apps'
    this.context.appActions.goToScreen('musicApps', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elQAServices = async () => {
    // Go to screen 'QA Services'
    this.context.appActions.goToScreen('QAServices', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elPrivacyPolicy = async () => {
    // Go to screen 'Privacy Policy'
    this.context.appActions.goToScreen('privacyPolicy', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#1f90be',
     };
    const style_elAboutUs = {
      fontSize: 20.0,
      color: '#feffff',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMathApps = {
      fontSize: 20.0,
      color: '#feffff',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elEntertainment = {
      fontSize: 20.0,
      color: '#feffff',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMusic = {
      fontSize: 20.0,
      color: '#feffff',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elQAServices = {
      fontSize: 20.0,
      color: '#feffff',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elPrivacyPolicy = {
      fontSize: 20.0,
      color: '#feffff',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Footer">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="hasNestedComps elSocial">
            <div>
              <Social ref={(el)=> this._elSocial = el} />
            </div>
          </div>
          
          <div className="elAboutUs">
            <div className="font-superclarendonRegular" style={style_elAboutUs} onClick={this.onClick_elAboutUs} >
              <div>{this.context.locStrings.footer_aboutuscopy_407307}</div>
            </div>
          </div>
          
          <div className="elMathApps">
            <div className="font-superclarendonRegular" style={style_elMathApps} onClick={this.onClick_elMathApps} >
              <div>{this.context.locStrings.footer_textcopy2_174373}</div>
            </div>
          </div>
          
          <div className="elEntertainment">
            <div className="font-superclarendonRegular" style={style_elEntertainment} onClick={this.onClick_elEntertainment} >
              <div>{this.context.locStrings.footer_mathappscopy_847562}</div>
            </div>
          </div>
          
          <div className="elMusic">
            <div className="font-superclarendonRegular" style={style_elMusic} onClick={this.onClick_elMusic} >
              <div>{this.context.locStrings.footer_entertainmentcopy_816139}</div>
            </div>
          </div>
          
          <div className="elQAServices">
            <div className="font-superclarendonRegular" style={style_elQAServices} onClick={this.onClick_elQAServices} >
              <div>{this.context.locStrings.footer_privacypolicycopy_920061}</div>
            </div>
          </div>
          
          <div className="elPrivacyPolicy">
            <div className="font-superclarendonRegular" style={style_elPrivacyPolicy} onClick={this.onClick_elPrivacyPolicy} >
              <div>{this.context.locStrings.footer_textcopy_654782}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
