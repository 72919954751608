// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_listData_row0_startplaying from './images/datasheet_listData_row0_startplaying.png';
import datasheet_listData_row0_picture from './images/datasheet_listData_row0_picture.png';
import datasheet_listData_row0_appleBadge from './images/datasheet_listData_row0_appleBadge.png';
import datasheet_listData_row0_rules from './images/datasheet_listData_row0_rules.png';
import datasheet_listData_row0_appleQRcode from './images/datasheet_listData_row0_appleQRcode.png';
import datasheet_listData_row0_certificate from './images/datasheet_listData_row0_certificate.png';
import datasheet_listData_row0_gameend from './images/datasheet_listData_row0_gameend.png';
import datasheet_listData_row0_havefun from './images/datasheet_listData_row0_havefun.png';
import datasheet_listData_row0_googleBadge from './images/datasheet_listData_row0_googleBadge.png';
import datasheet_listData_row0_googleQRcode from './images/datasheet_listData_row0_googleQRcode.png';
import datasheet_listData_row1_startplaying from './images/datasheet_listData_row1_startplaying.png';
import datasheet_listData_row1_picture from './images/datasheet_listData_row1_picture.png';
import datasheet_listData_row1_appleBadge from './images/datasheet_listData_row1_appleBadge.png';
import datasheet_listData_row1_rules from './images/datasheet_listData_row1_rules.png';
import datasheet_listData_row1_appleQRcode from './images/datasheet_listData_row1_appleQRcode.png';
import datasheet_listData_row1_certificate from './images/datasheet_listData_row1_certificate.png';
import datasheet_listData_row1_gameend from './images/datasheet_listData_row1_gameend.png';
import datasheet_listData_row1_havefun from './images/datasheet_listData_row1_havefun.png';
import datasheet_listData_row1_googleBadge from './images/datasheet_listData_row1_googleBadge.png';
import datasheet_listData_row1_googleQRcode from './images/datasheet_listData_row1_googleQRcode.png';
import datasheet_listData_row2_startplaying from './images/datasheet_listData_row2_startplaying.png';
import datasheet_listData_row2_picture from './images/datasheet_listData_row2_picture.png';
import datasheet_listData_row2_appleBadge from './images/datasheet_listData_row2_appleBadge.png';
import datasheet_listData_row2_rules from './images/datasheet_listData_row2_rules.png';
import datasheet_listData_row2_appleQRcode from './images/datasheet_listData_row2_appleQRcode.png';
import datasheet_listData_row2_certificate from './images/datasheet_listData_row2_certificate.png';
import datasheet_listData_row2_gameend from './images/datasheet_listData_row2_gameend.png';
import datasheet_listData_row2_havefun from './images/datasheet_listData_row2_havefun.png';
import datasheet_listData_row2_googleBadge from './images/datasheet_listData_row2_googleBadge.png';
import datasheet_listData_row2_googleQRcode from './images/datasheet_listData_row2_googleQRcode.png';
import datasheet_listData_row3_startplaying from './images/datasheet_listData_row3_startplaying.png';
import datasheet_listData_row3_picture from './images/datasheet_listData_row3_picture.png';
import datasheet_listData_row3_appleBadge from './images/datasheet_listData_row3_appleBadge.png';
import datasheet_listData_row3_rules from './images/datasheet_listData_row3_rules.png';
import datasheet_listData_row3_appleQRcode from './images/datasheet_listData_row3_appleQRcode.png';
import datasheet_listData_row3_certificate from './images/datasheet_listData_row3_certificate.png';
import datasheet_listData_row3_gameend from './images/datasheet_listData_row3_gameend.png';
import datasheet_listData_row3_havefun from './images/datasheet_listData_row3_havefun.png';
import datasheet_listData_row3_googleBadge from './images/datasheet_listData_row3_googleBadge.png';
import datasheet_listData_row3_googleQRcode from './images/datasheet_listData_row3_googleQRcode.png';
import datasheet_listData_row4_startplaying from './images/datasheet_listData_row4_startplaying.png';
import datasheet_listData_row4_picture from './images/datasheet_listData_row4_picture.png';
import datasheet_listData_row4_appleBadge from './images/datasheet_listData_row4_appleBadge.png';
import datasheet_listData_row4_rules from './images/datasheet_listData_row4_rules.png';
import datasheet_listData_row4_appleQRcode from './images/datasheet_listData_row4_appleQRcode.png';
import datasheet_listData_row4_certificate from './images/datasheet_listData_row4_certificate.png';
import datasheet_listData_row4_gameend from './images/datasheet_listData_row4_gameend.png';
import datasheet_listData_row4_havefun from './images/datasheet_listData_row4_havefun.png';
import datasheet_listData_row4_googleBadge from './images/datasheet_listData_row4_googleBadge.png';
import datasheet_listData_row4_googleQRcode from './images/datasheet_listData_row4_googleQRcode.png';
import datasheet_listData_row5_startplaying from './images/datasheet_listData_row5_startplaying.png';
import datasheet_listData_row5_picture from './images/datasheet_listData_row5_picture.png';
import datasheet_listData_row5_appleBadge from './images/datasheet_listData_row5_appleBadge.png';
import datasheet_listData_row5_rules from './images/datasheet_listData_row5_rules.png';
import datasheet_listData_row5_appleQRcode from './images/datasheet_listData_row5_appleQRcode.png';
import datasheet_listData_row5_certificate from './images/datasheet_listData_row5_certificate.png';
import datasheet_listData_row5_gameend from './images/datasheet_listData_row5_gameend.png';
import datasheet_listData_row5_havefun from './images/datasheet_listData_row5_havefun.png';
import datasheet_listData_row5_googleBadge from './images/datasheet_listData_row5_googleBadge.png';
import datasheet_listData_row5_googleQRcode from './images/datasheet_listData_row5_googleQRcode.png';
import datasheet_listData_row6_startplaying from './images/datasheet_listData_row6_startplaying.png';
import datasheet_listData_row6_picture from './images/datasheet_listData_row6_picture.png';
import datasheet_listData_row6_appleBadge from './images/datasheet_listData_row6_appleBadge.png';
import datasheet_listData_row6_rules from './images/datasheet_listData_row6_rules.png';
import datasheet_listData_row6_appleQRcode from './images/datasheet_listData_row6_appleQRcode.png';
import datasheet_listData_row6_certificate from './images/datasheet_listData_row6_certificate.png';
import datasheet_listData_row6_gameend from './images/datasheet_listData_row6_gameend.png';
import datasheet_listData_row6_havefun from './images/datasheet_listData_row6_havefun.png';
import datasheet_listData_row6_googleBadge from './images/datasheet_listData_row6_googleBadge.png';
import datasheet_listData_row6_googleQRcode from './images/datasheet_listData_row6_googleQRcode.png';
import datasheet_listData_row7_startplaying from './images/datasheet_listData_row7_startplaying.png';
import datasheet_listData_row7_picture from './images/datasheet_listData_row7_picture.png';
import datasheet_listData_row7_appleBadge from './images/datasheet_listData_row7_appleBadge.png';
import datasheet_listData_row7_rules from './images/datasheet_listData_row7_rules.png';
import datasheet_listData_row7_appleQRcode from './images/datasheet_listData_row7_appleQRcode.png';
import datasheet_listData_row7_certificate from './images/datasheet_listData_row7_certificate.png';
import datasheet_listData_row7_gameend from './images/datasheet_listData_row7_gameend.png';
import datasheet_listData_row7_havefun from './images/datasheet_listData_row7_havefun.png';
import datasheet_listData_row7_googleBadge from './images/datasheet_listData_row7_googleBadge.png';
import datasheet_listData_row7_googleQRcode from './images/datasheet_listData_row7_googleQRcode.png';
import datasheet_listData_row8_startplaying from './images/datasheet_listData_row8_startplaying.png';
import datasheet_listData_row8_picture from './images/datasheet_listData_row8_picture.png';
import datasheet_listData_row8_appleBadge from './images/datasheet_listData_row8_appleBadge.png';
import datasheet_listData_row8_rules from './images/datasheet_listData_row8_rules.png';
import datasheet_listData_row8_appleQRcode from './images/datasheet_listData_row8_appleQRcode.png';
import datasheet_listData_row8_certificate from './images/datasheet_listData_row8_certificate.png';
import datasheet_listData_row8_gameend from './images/datasheet_listData_row8_gameend.png';
import datasheet_listData_row8_havefun from './images/datasheet_listData_row8_havefun.png';
import datasheet_listData_row8_googleBadge from './images/datasheet_listData_row8_googleBadge.png';
import datasheet_listData_row8_googleQRcode from './images/datasheet_listData_row8_googleQRcode.png';
import datasheet_listData_row9_startplaying from './images/datasheet_listData_row9_startplaying.png';
import datasheet_listData_row9_picture from './images/datasheet_listData_row9_picture.png';
import datasheet_listData_row9_appleBadge from './images/datasheet_listData_row9_appleBadge.png';
import datasheet_listData_row9_rules from './images/datasheet_listData_row9_rules.png';
import datasheet_listData_row9_appleQRcode from './images/datasheet_listData_row9_appleQRcode.png';
import datasheet_listData_row9_certificate from './images/datasheet_listData_row9_certificate.png';
import datasheet_listData_row9_gameend from './images/datasheet_listData_row9_gameend.png';
import datasheet_listData_row9_havefun from './images/datasheet_listData_row9_havefun.png';
import datasheet_listData_row9_googleBadge from './images/datasheet_listData_row9_googleBadge.png';
import datasheet_listData_row9_googleQRcode from './images/datasheet_listData_row9_googleQRcode.png';
import datasheet_listData_row10_startplaying from './images/datasheet_listData_row10_startplaying.png';
import datasheet_listData_row10_picture from './images/datasheet_listData_row10_picture.png';
import datasheet_listData_row10_appleBadge from './images/datasheet_listData_row10_appleBadge.png';
import datasheet_listData_row10_rules from './images/datasheet_listData_row10_rules.png';
import datasheet_listData_row10_appleQRcode from './images/datasheet_listData_row10_appleQRcode.png';
import datasheet_listData_row10_certificate from './images/datasheet_listData_row10_certificate.png';
import datasheet_listData_row10_gameend from './images/datasheet_listData_row10_gameend.png';
import datasheet_listData_row10_havefun from './images/datasheet_listData_row10_havefun.png';
import datasheet_listData_row10_googleBadge from './images/datasheet_listData_row10_googleBadge.png';
import datasheet_listData_row10_googleQRcode from './images/datasheet_listData_row10_googleQRcode.png';
import datasheet_listData_row11_startplaying from './images/datasheet_listData_row11_startplaying.png';
import datasheet_listData_row11_picture from './images/datasheet_listData_row11_picture.png';
import datasheet_listData_row11_appleBadge from './images/datasheet_listData_row11_appleBadge.png';
import datasheet_listData_row11_rules from './images/datasheet_listData_row11_rules.png';
import datasheet_listData_row11_appleQRcode from './images/datasheet_listData_row11_appleQRcode.png';
import datasheet_listData_row11_certificate from './images/datasheet_listData_row11_certificate.png';
import datasheet_listData_row11_gameend from './images/datasheet_listData_row11_gameend.png';
import datasheet_listData_row11_havefun from './images/datasheet_listData_row11_havefun.png';
import datasheet_listData_row11_googleBadge from './images/datasheet_listData_row11_googleBadge.png';
import datasheet_listData_row11_googleQRcode from './images/datasheet_listData_row11_googleQRcode.png';
import datasheet_listData_row12_startplaying from './images/datasheet_listData_row12_startplaying.png';
import datasheet_listData_row12_picture from './images/datasheet_listData_row12_picture.png';
import datasheet_listData_row12_appleBadge from './images/datasheet_listData_row12_appleBadge.png';
import datasheet_listData_row12_rules from './images/datasheet_listData_row12_rules.png';
import datasheet_listData_row12_appleQRcode from './images/datasheet_listData_row12_appleQRcode.png';
import datasheet_listData_row12_certificate from './images/datasheet_listData_row12_certificate.png';
import datasheet_listData_row12_gameend from './images/datasheet_listData_row12_gameend.png';
import datasheet_listData_row12_havefun from './images/datasheet_listData_row12_havefun.png';
import datasheet_listData_row12_googleBadge from './images/datasheet_listData_row12_googleBadge.png';
import datasheet_listData_row12_googleQRcode from './images/datasheet_listData_row12_googleQRcode.png';

export default class DataSheet_listData extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row0_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "K";
    item['picture'] = datasheet_listData_row0_picture;
    item['appleBadge'] = datasheet_listData_row0_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.mathk";
    item['rules'] = datasheet_listData_row0_rules;
    item['appleQRcode'] = datasheet_listData_row0_appleQRcode;
    item['certificate'] = datasheet_listData_row0_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row0_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/kindergarten-math-challenge/id1600614251";
    item['have-fun'] = datasheet_listData_row0_havefun;
    item['long description'] = "Challenge your mind with Kindergarten grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row0_googleBadge;
    item['googleQRcode'] = datasheet_listData_row0_googleQRcode;
    item['description'] = "Kindergarten Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row1_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "1st";
    item['picture'] = datasheet_listData_row1_picture;
    item['appleBadge'] = datasheet_listData_row1_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math1";
    item['rules'] = datasheet_listData_row1_rules;
    item['appleQRcode'] = datasheet_listData_row1_appleQRcode;
    item['certificate'] = datasheet_listData_row1_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row1_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/1st-grade-math-challenge/id1567478595";
    item['have-fun'] = datasheet_listData_row1_havefun;
    item['long description'] = "Challenge your mind with 1st grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row1_googleBadge;
    item['googleQRcode'] = datasheet_listData_row1_googleQRcode;
    item['description'] = "1st Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row2_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "2nd";
    item['picture'] = datasheet_listData_row2_picture;
    item['appleBadge'] = datasheet_listData_row2_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math2";
    item['rules'] = datasheet_listData_row2_rules;
    item['appleQRcode'] = datasheet_listData_row2_appleQRcode;
    item['certificate'] = datasheet_listData_row2_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row2_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/2nd-grade-math-challenge/id1567478828";
    item['have-fun'] = datasheet_listData_row2_havefun;
    item['long description'] = "Challenge your mind with 2nd grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row2_googleBadge;
    item['googleQRcode'] = datasheet_listData_row2_googleQRcode;
    item['description'] = "2nd Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row3_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "3rd";
    item['picture'] = datasheet_listData_row3_picture;
    item['appleBadge'] = datasheet_listData_row3_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math3";
    item['rules'] = datasheet_listData_row3_rules;
    item['appleQRcode'] = datasheet_listData_row3_appleQRcode;
    item['certificate'] = datasheet_listData_row3_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row3_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/3rd-grade-math-challenge/id1567478703";
    item['have-fun'] = datasheet_listData_row3_havefun;
    item['long description'] = "Challenge your mind with 3rd grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row3_googleBadge;
    item['googleQRcode'] = datasheet_listData_row3_googleQRcode;
    item['description'] = "3rd Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row4_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "4th";
    item['picture'] = datasheet_listData_row4_picture;
    item['appleBadge'] = datasheet_listData_row4_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math4";
    item['rules'] = datasheet_listData_row4_rules;
    item['appleQRcode'] = datasheet_listData_row4_appleQRcode;
    item['certificate'] = datasheet_listData_row4_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row4_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/4th-grade-math-challenge-game/id1567511887";
    item['have-fun'] = datasheet_listData_row4_havefun;
    item['long description'] = "Challenge your mind with 4th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row4_googleBadge;
    item['googleQRcode'] = datasheet_listData_row4_googleQRcode;
    item['description'] = "4th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row5_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "5th";
    item['picture'] = datasheet_listData_row5_picture;
    item['appleBadge'] = datasheet_listData_row5_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math5";
    item['rules'] = datasheet_listData_row5_rules;
    item['appleQRcode'] = datasheet_listData_row5_appleQRcode;
    item['certificate'] = datasheet_listData_row5_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row5_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/5th-grade-math-challenge/id1531416757";
    item['have-fun'] = datasheet_listData_row5_havefun;
    item['long description'] = "Challenge your mind with 5th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row5_googleBadge;
    item['googleQRcode'] = datasheet_listData_row5_googleQRcode;
    item['description'] = "5th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row6_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "6th";
    item['picture'] = datasheet_listData_row6_picture;
    item['appleBadge'] = datasheet_listData_row6_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math6";
    item['rules'] = datasheet_listData_row6_rules;
    item['appleQRcode'] = datasheet_listData_row6_appleQRcode;
    item['certificate'] = datasheet_listData_row6_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row6_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/6th-grade-math-challenge/id1534522143";
    item['have-fun'] = datasheet_listData_row6_havefun;
    item['long description'] = "Challenge your mind with 6th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row6_googleBadge;
    item['googleQRcode'] = datasheet_listData_row6_googleQRcode;
    item['description'] = "6th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row7_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "7th";
    item['picture'] = datasheet_listData_row7_picture;
    item['appleBadge'] = datasheet_listData_row7_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math7";
    item['rules'] = datasheet_listData_row7_rules;
    item['appleQRcode'] = datasheet_listData_row7_appleQRcode;
    item['certificate'] = datasheet_listData_row7_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row7_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/7th-grade-math-challenge/id1537489188";
    item['have-fun'] = datasheet_listData_row7_havefun;
    item['long description'] = "Challenge your mind with 7th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row7_googleBadge;
    item['googleQRcode'] = datasheet_listData_row7_googleQRcode;
    item['description'] = "7th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row8_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "8th";
    item['picture'] = datasheet_listData_row8_picture;
    item['appleBadge'] = datasheet_listData_row8_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math8";
    item['rules'] = datasheet_listData_row8_rules;
    item['appleQRcode'] = datasheet_listData_row8_appleQRcode;
    item['certificate'] = datasheet_listData_row8_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row8_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/8th-grade-math-challenge/id1537490891";
    item['have-fun'] = datasheet_listData_row8_havefun;
    item['long description'] = "Challenge your mind with 8th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row8_googleBadge;
    item['googleQRcode'] = datasheet_listData_row8_googleQRcode;
    item['description'] = "8th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row9_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "9th";
    item['picture'] = datasheet_listData_row9_picture;
    item['appleBadge'] = datasheet_listData_row9_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math9";
    item['rules'] = datasheet_listData_row9_rules;
    item['appleQRcode'] = datasheet_listData_row9_appleQRcode;
    item['certificate'] = datasheet_listData_row9_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row9_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/9th-grade-math-challenge/id1537491129";
    item['have-fun'] = datasheet_listData_row9_havefun;
    item['long description'] = "Challenge your mind with 9th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row9_googleBadge;
    item['googleQRcode'] = datasheet_listData_row9_googleQRcode;
    item['description'] = "9th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row10_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "10th";
    item['picture'] = datasheet_listData_row10_picture;
    item['appleBadge'] = datasheet_listData_row10_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math10";
    item['rules'] = datasheet_listData_row10_rules;
    item['appleQRcode'] = datasheet_listData_row10_appleQRcode;
    item['certificate'] = datasheet_listData_row10_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row10_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/10th-grade-math-challenge/id1537491277";
    item['have-fun'] = datasheet_listData_row10_havefun;
    item['long description'] = "Challenge your mind with 10th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row10_googleBadge;
    item['googleQRcode'] = datasheet_listData_row10_googleQRcode;
    item['description'] = "10th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row11_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "11th";
    item['picture'] = datasheet_listData_row11_picture;
    item['appleBadge'] = datasheet_listData_row11_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math11";
    item['rules'] = datasheet_listData_row11_rules;
    item['appleQRcode'] = datasheet_listData_row11_appleQRcode;
    item['certificate'] = datasheet_listData_row11_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row11_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/11th-grade-math-challenge/id1624291993";
    item['have-fun'] = datasheet_listData_row11_havefun;
    item['long description'] = "Challenge your mind with 11th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row11_googleBadge;
    item['googleQRcode'] = datasheet_listData_row11_googleQRcode;
    item['description'] = "11th Grade Math Puzzle Challenge";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['start-playing'] = datasheet_listData_row12_startplaying;
    item['rulesText'] = "Follow Rules";
    item['title'] = "12th";
    item['picture'] = datasheet_listData_row12_picture;
    item['appleBadge'] = datasheet_listData_row12_appleBadge;
    item['googleLink'] = "https://play.google.com/store/apps/details?id=com.it365solutionsinc.math12";
    item['rules'] = datasheet_listData_row12_rules;
    item['appleQRcode'] = datasheet_listData_row12_appleQRcode;
    item['certificate'] = datasheet_listData_row12_certificate;
    item['haveFunText'] = "Have Fun";
    item['game-end'] = datasheet_listData_row12_gameend;
    item['gameEndText'] = "Complete the Game";
    item['appleLink'] = "https://apps.apple.com/us/app/12th-grade-math-challenge/id1626192903";
    item['have-fun'] = datasheet_listData_row12_havefun;
    item['long description'] = "Challenge your mind with 12th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most!";
    item['startPlayingText'] = "Start Playing";
    item['certificateText'] = "Celebrate and Share your Certificate";
    item['googleBadge'] = datasheet_listData_row12_googleBadge;
    item['googleQRcode'] = datasheet_listData_row12_googleQRcode;
    item['description'] = "12th Grade Math Puzzle Challenge";
    item.key = key++;
  }

}
