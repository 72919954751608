import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elCertificate1 from './images/TopScorerComponent_elCertificate1_542954.png';
import img_elCertificate2 from './images/TopScorerComponent_elCertificate2_624414.png';

export default class TopScorerComponent extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // grade, certificate1, certificate2

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const value_v5thGrade = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.grade);
    
    const style_elV5thGrade = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'center',
     };
    
    const value_certificate1 = this.props.certificate1;
    
    const style_elCertificate1 = {
      height: 'auto',
     };
    
    const value_certificate2 = this.props.certificate2;
    
    const style_elCertificate2 = {
      height: 'auto',
     };
    
    return (
      <div className="TopScorerComponent">
        <div className="layoutFlow">
          <div className="elV5thGrade">
            <div className="font-sourceSansProSemibold" style={style_elV5thGrade}>
              <div>{value_v5thGrade}</div>
            </div>
          </div>
          
          <div className="elCertificate1">
            <img style={style_elCertificate1} src={this.context.appActions.createImageUrlFromProp(value_certificate1)} alt=""  />
          </div>
          
          <div className="elCertificate2">
            <img style={style_elCertificate2} src={this.context.appActions.createImageUrlFromProp(value_certificate2)} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
