import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import btn_icon_689056 from './images/btn_icon_689056.png';
import img_elN2009Copy from './images/HeaderComponent_elN2009Copy_581687.jpg';
import Drawer3 from './Drawer3';

// UI framework component imports

export default class HeaderComponent extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      drawer3_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elIconButton = async () => {
    this.toggle_drawer3();
  
  }
  
  
  onClick_elText = async () => {
    // Go to screen 'About Us'
    this.context.appActions.goToScreen('aboutUs', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elN2009Copy = async () => {
    // Go to screen 'About Us'
    this.context.appActions.goToScreen('aboutUs', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  toggle_drawer3 = () => {
    this.setState({drawer3_isOpen: !this.state.drawer3_isOpen});
  }
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#1d91be',
     };
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_689056+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '70.417%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText = {
      fontSize: 25.0,
      color: '#feffff',
      textAlign: 'center',
      textTransform: 'uppercase',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elN2009Copy = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_drawer3 = {
      pointerEvents: (this.state.drawer3_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="HeaderComponent">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          <div className="font-superclarendonBlackItalic  elText" style={style_elText} onClick={this.onClick_elText} >
            <div>{this.context.locStrings.component1_text_1011643}</div>
          </div>
          <img className="elN2009Copy" style={style_elN2009Copy} src={img_elN2009Copy} alt="" onClick={this.onClick_elN2009Copy}  />
        </div>
        <div className="drawer drawer3" style={style_drawer3} onClick={this.toggle_drawer3}>
          <div className={"drawerContent" + (this.state.drawer3_isOpen ? " drawerOpen": " drawerClosedRight")} onClick={(e) => {e.stopPropagation();}}>
            <Drawer3 closeDrawer={this.toggle_drawer3} />
          </div>
        </div>
      </div>
    )
  }
  
}
