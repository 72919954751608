import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CategoriesTabs from './CategoriesTabs';
import img_elGrade5512_play_icon from './images/EntDetailsScreen_elGrade5512_play_icon_196464.jpg';
import img_elGooglePlayStore from './images/EntDetailsScreen_elGooglePlayStore_926386.png';
import img_elAppleAppStore from './images/EntDetailsScreen_elAppleAppStore_493867.jpg';
import img_elGoogleQRcode from './images/EntDetailsScreen_elGoogleQRcode_124279.jpg';
import img_elAppleQRcode from './images/EntDetailsScreen_elAppleQRcode_710187.jpg';
import img_elRules from './images/EntDetailsScreen_elRules_219862.jpg';
import img_elRulesCopy from './images/EntDetailsScreen_elRulesCopy_443106.jpg';
import img_elStartplaying from './images/EntDetailsScreen_elStartplaying_730728.jpg';
import img_elStartplayingCopy from './images/EntDetailsScreen_elStartplayingCopy_53395.jpg';
import img_elStartplayingCopy2 from './images/EntDetailsScreen_elStartplayingCopy2_190671.jpg';
import Footer from './Footer';
import HeaderComponent from './HeaderComponent';

export default class EntDetailsScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elGooglePlayStore = async () => {
    window.open(this.props.dataSheetRow ? this.props.dataSheetRow.googleLink : '', '_blank');
  
  }
  
  
  onClick_elAppleAppStore = async () => {
    window.open(this.props.dataSheetRow ? this.props.dataSheetRow.appleLink : '', '_blank');
  
  }
  
  
  onClick_elGoogleQRcode = async () => {
    window.open(this.props.dataSheetRow ? this.props.dataSheetRow.googleLink : '', '_blank');
  
  }
  
  
  onClick_elAppleQRcode = async () => {
    window.open(this.props.dataSheetRow ? this.props.dataSheetRow.appleLink : '', '_blank');
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    const value_grade5512_play_icon = this.props.dataSheetRow ? this.props.dataSheetRow.picture : '';
    
    const style_elGrade5512_play_icon = {
      height: 'auto',
     };
    
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.description : '');
    
    const style_elText = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'center',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow["long description"] : '');
    
    const style_elText2 = {
      fontSize: 25.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elTextCopy = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    
    const value_googlePlayStore = this.props.dataSheetRow ? this.props.dataSheetRow.googleBadge : '';
    
    const style_elGooglePlayStore = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_appleAppStore = this.props.dataSheetRow ? this.props.dataSheetRow.appleBadge : '';
    
    const style_elAppleAppStore = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_googleQRcode = this.props.dataSheetRow ? this.props.dataSheetRow.googleQRcode : '';
    
    const style_elGoogleQRcode = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_appleQRcode = this.props.dataSheetRow ? this.props.dataSheetRow.appleQRcode : '';
    
    const style_elAppleQRcode = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textCopy2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.rulesText : '');
    
    const style_elTextCopy2 = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elRules = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow.rules : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_textCopy3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.startPlayingText : '');
    
    const style_elTextCopy3 = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elRulesCopy = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow["start-playing"] : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_textCopy4 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.haveFunText : '');
    
    const style_elTextCopy4 = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elStartplaying = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow["have-fun"] : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_textCopy5 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.gameEndText : '');
    
    const style_elTextCopy5 = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elStartplayingCopy = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.dataSheetRow ? this.props.dataSheetRow["game-end"] : '')+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_textCopy6 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.dataSheetRow ? this.props.dataSheetRow.certificateText : '');
    
    const style_elTextCopy6 = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'left',
     };
    
    const value_startplayingCopy2 = this.props.dataSheetRow ? this.props.dataSheetRow.certificate : '';
    
    const style_elStartplayingCopy2 = {
      height: 'auto',
     };
    
    return (
      <div className="AppScreen EntDetailsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elCategoriesTabs">
            <div>
              <CategoriesTabs ref={(el)=> this._elCategoriesTabs = el} />
            </div>
          </div>
          
          <div className="elGrade5512_play_icon">
            <img style={style_elGrade5512_play_icon} src={this.context.appActions.createImageUrlFromProp(value_grade5512_play_icon)} alt=""  />
          </div>
          
          <div className="elText">
            <div className="font-sourceSansProSemibold" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elText2">
            <div className="font-AppleSystemUIFont" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="font-DINAlternateBold" style={style_elTextCopy}>
              <div>{this.context.locStrings.details2_textcopy_598734}</div>
            </div>
          </div>
          
          <div className="elGooglePlayStore">
            <img style={style_elGooglePlayStore} src={this.context.appActions.createImageUrlFromProp(value_googlePlayStore)} alt="" onClick={this.onClick_elGooglePlayStore}  />
          </div>
          
          <div className="elAppleAppStore">
            <img style={style_elAppleAppStore} src={this.context.appActions.createImageUrlFromProp(value_appleAppStore)} alt="" onClick={this.onClick_elAppleAppStore}  />
          </div>
          
          <div className="elGoogleQRcode">
            <img style={style_elGoogleQRcode} src={this.context.appActions.createImageUrlFromProp(value_googleQRcode)} alt="" onClick={this.onClick_elGoogleQRcode}  />
          </div>
          
          <div className="elAppleQRcode">
            <img style={style_elAppleQRcode} src={this.context.appActions.createImageUrlFromProp(value_appleQRcode)} alt="" onClick={this.onClick_elAppleQRcode}  />
          </div>
          
          <div className="elTextCopy2">
            <div className="font-DINAlternateBold" style={style_elTextCopy2}>
              <div>{value_textCopy2}</div>
            </div>
          </div>
          
          <div className="elRules">
            <div style={style_elRules} />
          </div>
          
          <div className="elTextCopy3">
            <div className="font-DINAlternateBold" style={style_elTextCopy3}>
              <div>{value_textCopy3}</div>
            </div>
          </div>
          
          <div className="elRulesCopy">
            <div style={style_elRulesCopy} />
          </div>
          
          <div className="elTextCopy4">
            <div className="font-DINAlternateBold" style={style_elTextCopy4}>
              <div>{value_textCopy4}</div>
            </div>
          </div>
          
          <div className="elStartplaying">
            <div style={style_elStartplaying} />
          </div>
          
          <div className="elTextCopy5">
            <div className="font-DINAlternateBold" style={style_elTextCopy5}>
              <div>{value_textCopy5}</div>
            </div>
          </div>
          
          <div className="elStartplayingCopy">
            <div style={style_elStartplayingCopy} />
          </div>
          
          <div className="elTextCopy6">
            <div className="font-DINAlternateBold" style={style_elTextCopy6}>
              <div>{value_textCopy6}</div>
            </div>
          </div>
          
          <div className="elStartplayingCopy2">
            <img style={style_elStartplayingCopy2} src={this.context.appActions.createImageUrlFromProp(value_startplayingCopy2)} alt=""  />
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elHeaderComponent">
              <HeaderComponent ref={(el)=> this._elHeaderComponent = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
