import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import CategoriesTabs from './CategoriesTabs';
import ImageMusic from './ImageMusic';
import EntItem from './EntItem';
import Footer from './Footer';
import HeaderComponent from './HeaderComponent';

export default class MusicAppsScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  onClick_elEllipse = async () => {
    // Go to screen 'Top Scorer'
    this.context.appActions.goToScreen('topScorer', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_musicData = this.context.dataSheets['musicData'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#88f4fb',
     };
    const style_elText = {
      fontSize: 40.0,
      color: 'black',
      textAlign: 'center',
     };
    const style_elTextCopy = {
      fontSize: 30.0,
      color: 'black',
      textAlign: 'center',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('musicData').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_elEllipse = {
      background: 'rgba(2, 35, 112, 1.000)',
      borderRadius: '50%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText2 = {
      fontSize: 25.0,
      color: '#feffff',
      textAlign: 'center',
     };
    const style_elMoreText = {
      fontSize: 25.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="AppScreen MusicAppsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elCategoriesTabs">
            <div>
              <CategoriesTabs ref={(el)=> this._elCategoriesTabs = el} />
            </div>
          </div>
          
          <div className="elText">
            <div className="font-sourceSansProRegular" style={style_elText}>
              <div>{this.context.locStrings.entertainmentapps2_text_463693}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elImageComponent">
            <div>
              <ImageMusic ref={(el)=> this._elImageComponent = el} />
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="font-sourceSansProSemibold" style={style_elTextCopy}>
              <div>{this.context.locStrings.entertainmentapps2_textcopy_16800}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <div style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <EntItem dataSheetId={'musicData'} dataSheetRow={row} {...{ 'picture': row['picture'], 'googleBadge': row['googleBadge'], 'googleLink': row['googleLink'], 'appleBadge': row['appleBadge'], 'appleLink': row['appleLink'], 'title': row['title'], 'appleQRcode': row['appleQRcode'], 'googleQRcode': row['googleQRcode'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </div>
          </div>
          
          <div className="elEllipse">
            <div style={style_elEllipse} onClick={this.onClick_elEllipse}  />
          </div>
          
          <div className="elText2">
            <div className="systemFontBold" style={style_elText2}>
              <div>{this.context.locStrings.entertainmentapps2_text2_650687}</div>
            </div>
          </div>
          
          <div className="elMoreText">
            <div className="font-helveticaBoldOblique" style={style_elMoreText}>
              <div>{this.context.locStrings.entertainmentapps2_moretext_568050}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="hasNestedComps elFooter">
            <div>
              <Footer ref={(el)=> this._elFooter = el} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elHeaderComponent">
              <HeaderComponent ref={(el)=> this._elHeaderComponent = el} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
