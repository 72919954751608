// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_802225";
    item['en'] = "Creative Solutions Inc";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textblock_417735";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textblock2_102188";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textblock3_495326";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_textblock_985148";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_textblock2_935083";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_textblock_233483";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_1000840";
    item['en'] = "Boost your IQ with these all new brain twisters";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen5_text_317059";
    item['en'] = "Boost your IQ with all new brain twisters! ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen5_text2_194419";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen5_text2_315834";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen5_text2_872748";
    item['en'] = "More apps coming soon…";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_button_140471";
    item['en'] = "About Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy_466495";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer1_buttoncopy2_130087";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_button_348519";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_text2_424412";
    item['en'] = "About Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy_892505";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text_543929";
    item['en'] = "Mission";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text2_162420";
    item['en'] = "Develop beautiful and useful apps for Apple App Store and Google Play store.\nProvide Quality Assurance services and software testing solutions for your enterprise applications.";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text3_30648";
    item['en'] = "5th Grade Math App";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_textcopy_622227";
    item['en'] = "Develop beautiful and useful apps for Apple App Store and Google Play store.";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text4_140573";
    item['en'] = "Chack out available apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text5_14128";
    item['en'] = "Coming Soon";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text6_353978";
    item['en'] = "Many more Educational and Interesting Apps.";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text7_18884";
    item['en'] = "Contact Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_textcopy_34357";
    item['en'] = "for any questions, don’t hesitate to contact us";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text8_377728";
    item['en'] = "If you have any questions or suggestions, do not hesitate to contact us at -";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_button_99979";
    item['en'] = "Click here";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_text_312621";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_text2_391011";
    item['en'] = "It365 Solutions Inc built the Math and Entertainment apps as an Ad Supported app. This SERVICE is provided by It365 Solutions Inc at no cost and is intended for use as is. There is an in-app purchase option that removes all ads.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_text3_526071";
    item['en'] = "Information";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_text4_274943";
    item['en'] = "We don’t collect personal information.\nWe don’t collect personal information.\nWe don’t collect personal information.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_text5_380344";
    item['en'] = "Contact Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_text6_601781";
    item['en'] = "for any questions, don’t hesitate to contact us.";
    
    item = {};
    this.items.push(item);
    item['key'] = "advitem_text_181121";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "advitem_text_437483";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text_750126";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text2_400138";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text2_589840";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text2_964889";
    item['en'] = "lorem dgfdgfcdgfcjhgcfghjfghfgcgvfhgfhgfhgfhgfhgfhgfhgfvhgvhgvhgvhgvhgvhgvhgvhgvghvhgvhgvhgvhgvhgvhgvhgvhgvghv";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_1011643";
    item['en'] = "IT365 Solutions Inc";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy2_867295";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy_149020";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_button_720285";
    item['en'] = "About Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_text_80215";
    item['en'] = "5th Grade Math App";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_textcopy_1019007";
    item['en'] = "6th Grade Math App";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy3_61755";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy4_450442";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_buttoncopy5_615763";
    item['en'] = "Home";
    
    item = {};
    this.items.push(item);
    item['key'] = "advitem2_title_370928";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_homecopy_593457";
    item['en'] = "Home";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_text3_98181";
    item['en'] = "Boost your IQ with these all new brain twisters";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_textcopy_558816";
    item['en'] = "Boost your IQ with these all new brain twisters.";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_textcopy2_440047";
    item['en'] = "More apps coming soon…";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_textcopy_624555";
    item['en'] = "ABC123 Solutions Inc";
    
    item = {};
    this.items.push(item);
    item['key'] = "imagecomponent_text_528403";
    item['en'] = "Boost your IQ with these all new brain twisters";
    
    item = {};
    this.items.push(item);
    item['key'] = "imagecomponent_text_1029207";
    item['en'] = "Boost your IQ with all new brain twisters";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_textcopy_886595";
    item['en'] = "Check out our free Math apps available on Google play store and Apple app store. ";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_text_20133";
    item['en'] = "About Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_text2_869194";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_textcopy_654782";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_textcopy2_174373";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_textcopy3_385141";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "advitem_titlecopy_897200";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_textcopy_327016";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_textcopy2_611141";
    item['en'] = "Check out our privacy policy for Apps.";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_button2_129756";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_button2_758042";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_missioncopy_149750";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_textcopy_469350";
    item['en'] = "5th Grade Math App";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy_449292";
    item['en'] = "This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy2_218886";
    item['en'] = "This Privacy Policy applies to all our apps.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_privacypolicycopy_108848";
    item['en'] = "Information Collection and Use";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy3_698180";
    item['en'] = "We do not collect any personal information.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_informationcopy_945160";
    item['en'] = "Information Collection and Use";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy4_710396";
    item['en'] = "The app does use third party services that may collect information used to identify you.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy5_126935";
    item['en'] = "Link to privacy policy of third party service providers used by the app";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy6_157145";
    item['en'] = "\t•\tGoogle Play Services\n\t•\tAdMob\n\t•\tGoogle Analytics for Firebase\n";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_text_854779";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy6_414150";
    item['en'] = "Google Play Services";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_googleplaysvclinkcopy_988883";
    item['en'] = "AdMob";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_admoblinkcopy_954912";
    item['en'] = "Google Analytics for Firebase";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_informationcopy_443560";
    item['en'] = "Cookies";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy6_242825";
    item['en'] = "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy7_618762";
    item['en'] = "This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that may use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_cookiescopy_876198";
    item['en'] = "Links to Other Sites";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy8_578902";
    item['en'] = "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_linkscopy_390084";
    item['en'] = "Children’s Privacy";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy9_466598";
    item['en'] = "We comply with requirements for laws like the Children’s Online Privacy Protection Act (“COPPA”), the European Union’s General Data Protection Regulation (“GDPR”), and any other applicable regulations or laws.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_childrensprivacycopy_762520";
    item['en'] = "Changes to This Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy10_650743";
    item['en'] = "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy11_409749";
    item['en'] = "This policy is effective as of 2020-08-23";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_changescopy_242395";
    item['en'] = "Contact Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_textcopy12_310992";
    item['en'] = "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at -";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy_admoblinkcopy_876816";
    item['en'] = "support@it365solutionsinc.com";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_emaillink_167970";
    item['en'] = "support@it365solutionsinc.com";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text_996738";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy_345602";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text_658563";
    item['en'] = "5th Grade Math Puzzle Challenge\n";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text2_732612";
    item['en'] = "Challenge your mind with 5th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most.";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy_683630";
    item['en'] = "Play Now";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_text3_288548";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy2_764834";
    item['en'] = "Check out the Rules";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy3_766297";
    item['en'] = "Start Playing";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy4_472742";
    item['en'] = "Have Fun";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy5_384262";
    item['en'] = "Complete the Game";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy6_118428";
    item['en'] = "Start Playing";
    
    item = {};
    this.items.push(item);
    item['key'] = "details_textcopy6_69019";
    item['en'] = "Celebrate and Share your Certificate";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_button_1024391";
    item['en'] = "See top scorers of this month";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_button_241072";
    item['en'] = "See Top Scorers of this month";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_text2_640210";
    item['en'] = "See the Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_privacypolicy_830093";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_text2_841280";
    item['en'] = "It365 Solutions Inc built the Math app as an Ad Supported app. This SERVICE is provided by It365 Solutions Inc at no cost and is intended for use as is. There is an in-app purchase option that removes all ads.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy_173426";
    item['en'] = "This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy2_504143";
    item['en'] = "This Privacy Policy applies to our these apps: Grade 5 Math, Grade 6 Math, Grade 7 Math, Grade 8 Math, Grade 9 Math, Grade 10 Math.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_information_728076";
    item['en'] = "Information Collection and Use";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy3_210385";
    item['en'] = "We do not collect any personal information.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy4_523954";
    item['en'] = "The app does use third party services that may collect information used to identify you.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy5_729363";
    item['en'] = "Link to privacy policy of third party service providers used by the app";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_googleplaysvclink_1028058";
    item['en'] = "Google Play Services";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_admoblink_130148";
    item['en'] = "AdMob";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_googleanalyticslink_712663";
    item['en'] = "Google Analytics for Firebase";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_cookies_1046952";
    item['en'] = "Cookies";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy6_466046";
    item['en'] = "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy7_572499";
    item['en'] = "This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that may use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_links_410052";
    item['en'] = "Links to Other Sites";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy8_503585";
    item['en'] = "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_childrensprivacy_499837";
    item['en'] = "Children’s Privacy";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy9_485978";
    item['en'] = "We comply with requirements for laws like the Children’s Online Privacy Protection Act (“COPPA”), the European Union’s General Data Protection Regulation (“GDPR”), and any other applicable regulations or laws.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_changes_442126";
    item['en'] = "Changes to This Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy10_1044985";
    item['en'] = "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes.";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy11_892235";
    item['en'] = "This policy is effective as of 2020-08-23";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_contactus_635609";
    item['en'] = "Contact Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_textcopy12_272192";
    item['en'] = "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at -";
    
    item = {};
    this.items.push(item);
    item['key'] = "privacypolicy2_emaillink_433501";
    item['en'] = "support@it365solutionsinc.com";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_moretext_32065";
    item['en'] = "Dare to beat them??";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_text_202024";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_textcopy_698399";
    item['en'] = "Complete the challenge and send your certificate to -";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_moretextcopy_860681";
    item['en'] = "Dare to beat them??";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorercomponent_text_421859";
    item['en'] = "Harish Shukla";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_textcopy2_270658";
    item['en'] = "Download the app and start playing";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_emaillink_955503";
    item['en'] = "support@it365solutionsinc.com";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_textcopy3_381291";
    item['en'] = "5th Grade";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_textcopy4_726176";
    item['en'] = "6th Grade";
    
    item = {};
    this.items.push(item);
    item['key'] = "n5topscorercomponent2_text_277392";
    item['en'] = "Harish Shukla";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_privacypolicycopy_347349";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_privacypolicystatecopy_898471";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_text2_414556";
    item['en'] = "See the Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "topscorer_n6thgradecopy_989887";
    item['en'] = "7th Grade";
    
    item = {};
    this.items.push(item);
    item['key'] = "n5topscorercomponent_textcopy_1043944";
    item['en'] = "Harish Shukla";
    
    item = {};
    this.items.push(item);
    item['key'] = "n5topscorercomponent_n5thgrade_458598";
    item['en'] = "5th Grade";
    
    item = {};
    this.items.push(item);
    item['key'] = "n5topscorercomponent_namecopy_912247";
    item['en'] = "Harish Shukla";
    
    item = {};
    this.items.push(item);
    item['key'] = "n5topscorercomponent_n5thgradecopy_498310";
    item['en'] = "5th Grade";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_aboutuscopy_407307";
    item['en'] = "About Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen2_text_674895";
    item['en'] = "Entertainment Trivia";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen2_textcopy_122107";
    item['en'] = "Check out our fun apps available on Google play store and Apple app store. ";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen2_text2_440819";
    item['en'] = "See the Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen2_moretext_335150";
    item['en'] = "More apps coming soon…";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_topscorerscopy_399000";
    item['en'] = "ENTERTAINMENT";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_topscorersstatecopy_681792";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_mathappscopy_847562";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_button_815343";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "homescreen_mathappscopy_303511";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_button_712508";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_mathappscopy_295107";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_textcopy_503458";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_textcopy2_623857";
    item['en'] = "Maths";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_textcopy3_274979";
    item['en'] = "IT365 Solutions Inc";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem2_title_112929";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_text2_109286";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "headercomponent_textcopy_436191";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "categoriestabs_button_969308";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "categoriestabs_mathappscopy_741834";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "categoriestabs_mathappscopy_540198";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "advitem2_title_888551";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_text_399435";
    item['en'] = "5th Grade Math Puzzle Challenge\n";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_text2_415661";
    item['en'] = "Challenge your mind with 5th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most.";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_textcopy_598734";
    item['en'] = "Play Now";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_textcopy2_472478";
    item['en'] = "Check out the Rules";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_textcopy3_153304";
    item['en'] = "Start Playing";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_textcopy4_105265";
    item['en'] = "Have Fun";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_textcopy5_484876";
    item['en'] = "Complete the Game";
    
    item = {};
    this.items.push(item);
    item['key'] = "details2_textcopy6_948008";
    item['en'] = "Celebrate and Share your Certificate";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_mathappscopy_640104";
    item['en'] = "Entertainment Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_mathappscopy_491807";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_mathappsexpcopy_674270";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_mathappscopy_711639";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_mathappscopy2_72220";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_topscorersstatecopy_427721";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_entappscopy_782263";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_entappsexpcopy_269029";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_privacypolicystatecopy_514833";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer2_topscorersstate3copy_924980";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem2_title_689389";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "advitem_text_326791";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_text_250273";
    item['en'] = "5th Grade Math Puzzle Challenge\n";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_text2_282977";
    item['en'] = "Challenge your mind with 5th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most.";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy_116518";
    item['en'] = "Play Now";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy2_114394";
    item['en'] = "Check out the Rules";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy3_909427";
    item['en'] = "Start Playing";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy4_395011";
    item['en'] = "Have Fun";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy5_713212";
    item['en'] = "Complete the Game";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy6_676958";
    item['en'] = "Celebrate and Share your Certificate";
    
    item = {};
    this.items.push(item);
    item['key'] = "advitem_titlecopy_917479";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "categoriestabs_entertainmentcopy_665372";
    item['en'] = "Music";
    
    item = {};
    this.items.push(item);
    item['key'] = "entertainmentapps2_text_463693";
    item['en'] = "Music Trivia";
    
    item = {};
    this.items.push(item);
    item['key'] = "entertainmentapps2_textcopy_16800";
    item['en'] = "Check out our fun apps available on Google play store and Apple app store. ";
    
    item = {};
    this.items.push(item);
    item['key'] = "entertainmentapps2_text2_650687";
    item['en'] = "See the Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "entertainmentapps2_moretext_568050";
    item['en'] = "More apps coming soon…";
    
    item = {};
    this.items.push(item);
    item['key'] = "listentitem2_title_598084";
    item['en'] = "1st Grade";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_entappscopy_1029088";
    item['en'] = "MUSIC";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_entappsstate2copy_999375";
    item['en'] = "MUSIC";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_entappsexpcopy_82052";
    item['en'] = "MUSIC";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_mathappsstate3copy_304872";
    item['en'] = "MUSIC";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_musicappscopy_517816";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_mathappsstate3copy_315875";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_entappsstate2copy_767725";
    item['en'] = "Entertainment";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_mathappsstate4copy_267338";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_topscorersstate3copy_23955";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_topscorerscopy_161671";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_privacypolicystate3copy_155166";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_topscorersstate4copy_71805";
    item['en'] = "Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_text_681181";
    item['en'] = "5th Grade Math Puzzle Challenge\n";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_text2_979924";
    item['en'] = "Challenge your mind with 5th grade level Math and see where you stand. There are 100 questions/levels sprinkled with some interesting Math puzzles in between. Create and share a certificate of your final score. Challenge your friends and family to see who can score the most.";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy_903753";
    item['en'] = "Play Now";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy2_432110";
    item['en'] = "Check out the Rules";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy3_201816";
    item['en'] = "Start Playing";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy4_675432";
    item['en'] = "Have Fun";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy5_411989";
    item['en'] = "Complete the Game";
    
    item = {};
    this.items.push(item);
    item['key'] = "entdetails2_textcopy6_501353";
    item['en'] = "Celebrate and Share your Certificate";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_entertainmentappscopy_35050";
    item['en'] = "Music Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "entitem2_title_982500";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_entertainmentcopy_816139";
    item['en'] = "Music";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_privacypolicycopy_891467";
    item['en'] = "QA Services";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_privacypolicystate3copy_384422";
    item['en'] = "Services";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_mathappsexpcopy_783888";
    item['en'] = "Math Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_privacypolicystate4copy_258384";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_text_20744";
    item['en'] = "Kindergarten\n1st Grade\n2nd Grade\n3rd Grade\n4th Grade\n5th Grade\n6th Grade\n7th Grade\n8th Grade\n9th Grade\n";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem2_title_349683";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen27_navbar_347133";
    item['en'] = "Screen 27";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_privacypolicystate2copy_218166";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "drawer3_privacypolicystate4copy_65961";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "categoriestabs_musiccopy_79617";
    item['en'] = "QA Services";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_mission_161445";
    item['en'] = "Quality Assurance Services";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_missionstatement_589207";
    item['en'] = "We provide manual and automated testing solutions as a standalone service.";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_mathapps_550395";
    item['en'] = "Our Team";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_entertainmentapps_504598";
    item['en'] = "Entertainment Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_musicapps_231238";
    item['en'] = "Music Apps";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_text2_421641";
    item['en'] = "See the Top Scorers";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_comingsoon_105792";
    item['en'] = "Types of Testing";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_comingsoontext_348858";
    item['en'] = "-> Functional Testing\n-> API Testing\n-> Database Testing\n-> Accessibility Testing\n-> Compatibility Testing\n-> Mobile apps Testing\n-> Website Testing\n-> Usability Testing\n-> Performance Testing\n-> User Acceptance Testing";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_privacypolicy_1040213";
    item['en'] = "Reporting";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_privacypolicylink_222926";
    item['en'] = "We provide detailed reporting to our customers. The reports reflect summary and detailed view of test cases passed / failed.\nWe use Jira tool for defect reporting and tracking.";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_privacypolicybutton_879430";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_contactus_620638";
    item['en'] = "Contact Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_contactdetails_357559";
    item['en'] = "To get quality testing for your products please contact us at -";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus2_emaillink_760067";
    item['en'] = "support@it365solutionsinc.com";
    
    item = {};
    this.items.push(item);
    item['key'] = "qaservices_comingsoontextcopy_739340";
    item['en'] = "Our team comprises of 25+ QA engineers with expertise in automation and manual testing.\n\nWe use automation tools like Selenium / Cypress with various programming languages including Java / Python / Ruby / C#\n\nOur Testing Lab is equipped with 100+ devices including a wide range of mobile devices which support the most used versions of iOS and Android.\n\nWe perform the testing on all commonly used browsers and operating systems.";
    
    item = {};
    this.items.push(item);
    item['key'] = "qaservices_contactuscopy_540923";
    item['en'] = "Join Our Team";
    
    item = {};
    this.items.push(item);
    item['key'] = "qaservices_contactdetailscopy_121307";
    item['en'] = "Interested in joining our team ?? ";
    
    item = {};
    this.items.push(item);
    item['key'] = "qaservices_contactuscopy2_936263";
    item['en'] = "Contact Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "qaservices_emaillinkcopy_437173";
    item['en'] = "careers@it365solutionsinc.com";
    
    item = {};
    this.items.push(item);
    item['key'] = "qaservices_contactdetailscopy2_447237";
    item['en'] = "To get quality testing for your products please contact us at -";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_privacypolicycopy_920061";
    item['en'] = "QA Services";
    
    item = {};
    this.items.push(item);
    item['key'] = "qaservices_jointeamtextcopy_932484";
    item['en'] = "Send your resume to -";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_contactuscopy_275015";
    item['en'] = "Contact Us";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_contactdetailscopy_695726";
    item['en'] = "If you have any questions or suggestions, do not hesitate to contact us at -";
    
    item = {};
    this.items.push(item);
    item['key'] = "aboutus_emaillinkcopy_362489";
    item['en'] = "support@it365solutionsinc.com";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
