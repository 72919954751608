// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_topScorerData_row0_certificate1 from './images/datasheet_topScorerData_row0_certificate1.png';
import datasheet_topScorerData_row0_certificate2 from './images/datasheet_topScorerData_row0_certificate2.png';
import datasheet_topScorerData_row1_certificate1 from './images/datasheet_topScorerData_row1_certificate1.png';
import datasheet_topScorerData_row1_certificate2 from './images/datasheet_topScorerData_row1_certificate2.png';
import datasheet_topScorerData_row2_certificate1 from './images/datasheet_topScorerData_row2_certificate1.png';
import datasheet_topScorerData_row2_certificate2 from './images/datasheet_topScorerData_row2_certificate2.png';
import datasheet_topScorerData_row3_certificate1 from './images/datasheet_topScorerData_row3_certificate1.png';
import datasheet_topScorerData_row3_certificate2 from './images/datasheet_topScorerData_row3_certificate2.png';
import datasheet_topScorerData_row4_certificate1 from './images/datasheet_topScorerData_row4_certificate1.png';
import datasheet_topScorerData_row4_certificate2 from './images/datasheet_topScorerData_row4_certificate2.png';

export default class DataSheet_topScorerData extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['grade'] = "5th Grade";
    item['certificate1'] = datasheet_topScorerData_row0_certificate1;
    item['certificate2'] = datasheet_topScorerData_row0_certificate2;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['grade'] = "6th Grade";
    item['certificate1'] = datasheet_topScorerData_row1_certificate1;
    item['certificate2'] = datasheet_topScorerData_row1_certificate2;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['grade'] = "7th Grade";
    item['certificate1'] = datasheet_topScorerData_row2_certificate1;
    item['certificate2'] = datasheet_topScorerData_row2_certificate2;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['grade'] = "8th Grade";
    item['certificate1'] = datasheet_topScorerData_row3_certificate1;
    item['certificate2'] = datasheet_topScorerData_row3_certificate2;
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['grade'] = "Seinfeld Trivia";
    item['certificate1'] = datasheet_topScorerData_row4_certificate1;
    item['certificate2'] = datasheet_topScorerData_row4_certificate2;
    item.key = key++;
  }

}
