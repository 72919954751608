import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Video extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/l0DLiLXu7NQ\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>";
    
    const style_elEmbed = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Video">
        <div className="foreground">
          <div className="embeddedContent elEmbed" style={style_elEmbed}>
            <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
          </div>
        </div>
      </div>
    )
  }
  
}
